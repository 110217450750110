import React, { useRef, useState } from 'react';
import { db } from '../firebase';
import { Link } from 'react-router-dom';
import { fetchFromAPI } from '../helpers';

export default function Cargr(props) {
    document.querySelector("#nav-bar")?.classList.remove("hidden");
    while (document.body.classList.length > 0) { document.body.classList.remove(document.body.classList[0]) };
    document.body.classList.add('cargr-page');
    const { userUID, userFirestoreData, loadingScreenData_Main } = props.data;
    const { dataReady_userFirestoreData, dataReady_subscriptions } = loadingScreenData_Main;

    if (!dataReady_userFirestoreData || !dataReady_subscriptions) { return (<></>) }
    if (!userFirestoreData?.cargrConnectionStatus) { return (<><NotConnected userUID={userUID} data={props.data} /></>) }
    else if (userFirestoreData?.cargrConnectionStatus === "err-other") { return (<><InternalError data={props.data} userUID={userUID} /></>); }
    else if (userFirestoreData?.cargrConnectionStatus === "err-cred") { return (<><Disconnected userUID={userUID} data={props.data} /></>); }
    else if (userFirestoreData?.cargrConnectionStatus === "ok" || userFirestoreData?.cargrConnectionStatus === "ok-exp") { return (<><Connected data={props.data} userUID={userUID} userFirestoreData={userFirestoreData} /></>); }
}

function InternalError(props) {
    const { userUID, userFirestoreData } = props;
    const { getSubscriptions } = props.data.fn;
    const { subscriptionId } = props.data.subscriptionInfo;
    const [mode, setMode] = useState(userFirestoreData?.updateMode || "simple");
    return (
        <>
            <div class="settings-container-outer">
                <div class="settings-container-inner">
                    <div class="data-panel-settings settings-cargr">
                        <div class="settings-head">
                            <div class="settings-heading">Ρυθμίσεις Car</div>
                            {/* <div class="settings-subheading">Έχετε συνδέσει τον λογαριασμό {userFirestoreData?.cargrCredentials?.username}</div> */}
                        </div>
                        <div class="settings-group account-status-connected account-status-cred">
                            <div class="settings-group-head">
                                <div class="settings-group-heading">Σύνδεση</div>
                                <div class="settings-group-sub-heading">Επεξεργαστείτε την σύνδεση του λογαριασμού car με την πλατφόρμα</div>
                            </div>
                            <div class="settings-group-body">
                                <div class="settings-item account-connection-status">
                                    <div class="settings-item-heading">Κατάσταση</div>
                                    <div class="settings-item-options">
                                        <div class="settings-item-option">
                                            <div class="settings-item-option-action">
                                                <div style={{ "margin-top": "3px", "width": "10px", "height": "10px", "border-radius": "5px", "background-color": "tomato" }}></div>
                                            </div>
                                            <div class="settings-item-option-head">
                                                <div class="settings-item-option-heading">Σφάλμα</div>
                                                <div class="settings-item-option-subheading">Αντιμετωπίζουμε ένα σφάλμα και εργαζόμαστε με επιμέλεια για να το λύσουμε. Η συνδρομή σας έχει τεθεί σε παύση και δεν χρεώνεστε. Όταν το σφάλμα επιλυθεί οι ανανεώσεις θα συνεχιστούν να λειτουργούν κανονικά.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="settings-item account-status-connected account-status-cred">
                                    <div class="settings-item-heading">Επεξεργασία σύνδεσης</div>
                                    <div class="settings-item-options">
                                        <div class="settings-item-option account-status-connected">
                                            <div class="settings-item-option-head">
                                                <div class="settings-item-option-heading action" onClick={() => disconnect(userUID, getSubscriptions, subscriptionId)}>Αποσύνδεση car</div>
                                                {/* <div class="settings-item-option-subheading">Αποσυνδέοντας τον λογαριασμό του car οι αυτόματες ανανεώσεις θα σταματήσουν να λειτουργούν.</div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <ChooseMode data={{ mode, setMode, userUID }} TODO: REACTIVATE IT /> */}
                        {mode === "simple" ? (
                            // Simple Mode
                            <>
                                <UpdateLimitAndKeyword data={props.data} />
                                <ChooseCategory data={props.data} />
                            </>
                        ) : (
                            // Andavced Mode
                            <>
                                <DragAndDrop data={props.data} />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

// function NotConnected(props) {
//     const { img_arrow } = props.data.img;
//     return (
//         <>
//             <div class="data-panel-settings settings-cargr not-connected">
//                 <div class="settings-head">
//                     <div class="settings-heading">Σύνδεση λογαριασμού Car</div>
//                     <div class="settings-subheading">Δεν έχετε συνδέσει ακόμα λογαριασμό car με την πλατφόρμα</div>
//                 </div>
//                 {/* <div class="line-wrap"><div class="line"></div></div> */}
//                 <div class="settings-group" style={{ "padding-top": "0" }}>
//                     <Link to="/car-connect" style={{ "alignSelf": "center" }}>
//                         <div class="settings-button" style={{ "margin-top": "0", "align-self": "center", "width": "50px" }}>Σύνδεση <img src={img_arrow} alt="" /></div>
//                     </Link>
//                 </div>
//             </div>
//         </>
//     )
// }

function NotConnected(props) {
    const { img_arrow } = props.data.img;
    const { userUID, userFirestoreData } = props;
    const { getSubscriptions } = props.data.fn;
    const { subscriptionId } = props.data.subscriptionInfo;
    const [mode, setMode] = useState(userFirestoreData?.updateMode || "simple");
    return (
        <>
            <div class="settings-container-outer disconnected">
                <div class="settings-container-inner">
                    <div class="data-panel-settings settings-cargr">
                        <div class="settings-head">
                            <div class="settings-heading">Ρυθμίσεις Car</div>
                            <div class="settings-subheading">Δεν έχετε συνδέσει ακόμα λογαριασμό car με την πλατφόρμα</div>
                        </div>
                        <div class="settings-group account-status-connected account-status-cred">
                            <div class="settings-group-head">
                                <div class="settings-group-heading">Σύνδεση</div>
                                <div class="settings-group-sub-heading">Επεξεργαστείτε την σύνδεση του λογαριασμού car με την πλατφόρμα</div>
                            </div>
                            <div class="settings-group-body">
                                <div class="settings-item account-connection-status">
                                    <div class="settings-item-heading">Κατάσταση</div>
                                    <div class="settings-item-options">
                                        <div class="settings-item-option">
                                            <div class="settings-item-option-action">
                                                <div style={{ "margin-top": "3px", "width": "10px", "height": "10px", "border-radius": "5px", "background-color": "orange" }}></div>
                                            </div>
                                            <div class="settings-item-option-head">
                                                <div class="settings-item-option-heading">Δεν έχει συνδεθεί</div>
                                                <div class="settings-item-option-subheading">Ο λογαριασμός car δεν έχει συνδεθεί με την πλατφόρμα. Παρακαλώ συνδεθείτε παρακάτω</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="settings-item account-status-connected account-status-cred">
                                    <div class="settings-item-heading">Επεξεργασία σύνδεσης</div>
                                    <div class="settings-item-options">
                                        <div class="settings-item-option wrap-768">
                                            <div class="settings-item-option-action">
                                                <Link to="/car-connect">
                                                    <div class="settings-button">Σύνδεση <img src={img_arrow} alt="" /></div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <ChooseMode data={{ mode, setMode, userUID }} TODO: REACTIVATE IT /> */}
                        {/* {mode === "simple" ? (
                            // Simple Mode
                            <>
                                <UpdateLimitAndKeyword data={props.data} />
                                <ChooseCategory data={props.data} />
                            </>
                        ) : (
                            // Andavced Mode
                            <>
                                <DragAndDrop data={props.data} />
                            </>
                        )} */}
                    </div>
                </div>
            </div>
        </>
    )
}

function Connected(props) {
    const { userUID, userFirestoreData } = props;
    const { getSubscriptions } = props.data.fn;
    const { subscriptionId } = props.data.subscriptionInfo;

    const [mode, setMode] = useState(userFirestoreData?.updateMode || "simple");

    return (
        <>
            <div class="settings-container-outer">
                <div class="settings-container-inner">
                    <div class="data-panel-settings settings-cargr">
                        <div class="settings-head">
                            <div class="settings-heading">Ρυθμίσεις Car</div>
                            <div class="settings-subheading">Έχετε συνδέσει τον λογαριασμό {userFirestoreData?.cargrCredentials?.username}</div>
                        </div>
                        <div class="settings-group account-status-connected account-status-cred">
                            <div class="settings-group-head">
                                <div class="settings-group-heading">Σύνδεση</div>
                                <div class="settings-group-sub-heading">Επεξεργαστείτε την σύνδεση του λογαριασμού car με την πλατφόρμα</div>
                            </div>
                            <div class="settings-group-body">
                                <div class="settings-item account-connection-status">
                                    <div class="settings-item-heading">Κατάσταση</div>
                                    <div class="settings-item-options">
                                        <div class="settings-item-option">
                                            <div class="settings-item-option-action">
                                                <div style={{ "margin-top": "3px", "width": "10px", "height": "10px", "border-radius": "5px", "background-color": "limegreen" }}></div>
                                            </div>
                                            <div class="settings-item-option-head">
                                                <div class="settings-item-option-heading">Συνδεδεμένο</div>
                                                <div class="settings-item-option-subheading">Όλα λειτουργούν κανονικά</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="settings-item account-status-connected account-status-cred">
                                    <div class="settings-item-heading">Επεξεργασία σύνδεσης</div>
                                    <div class="settings-item-options">
                                        <div class="settings-item-option wrap-768">
                                            <div class="settings-item-option-action">
                                                <Link to="/car-connect">
                                                    <div class="settings-button">Επανασύνδεση</div>
                                                </Link>
                                            </div>
                                            <div class="settings-item-option-head account-status-connected">
                                                <div class="settings-item-option-subheading">Αν αλλάξετε το όνομα χρήστη ή τον κωδικό πρόσβασης στο car πρέπει να επανασυδεθείτε με τα νέα στοιχεία σύνδεσης, διαφορετικα οι αυτόματες ανανεώσεις θα σταματήσουν να λειτουργούν.</div>
                                            </div>
                                        </div>
                                        <div class="settings-item-option account-status-connected">
                                            <div class="settings-item-option-head">
                                                <div class="settings-item-option-heading action" onClick={() => disconnect(userUID, getSubscriptions, subscriptionId)}>Αποσύνδεση car</div>
                                                <div class="settings-item-option-subheading">Αποσυνδέοντας τον λογαριασμό του car οι αυτόματες ανανεώσεις θα σταματήσουν να λειτουργούν.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <ChooseMode data={{ mode, setMode, userUID }} TODO: REACTIVATE IT /> */}
                        {mode === "simple" ? (
                            // Simple Mode
                            <>
                                <UpdateLimitAndKeyword data={props.data} />
                                <ChooseCategory data={props.data} />
                            </>
                        ) : (
                            // Andavced Mode
                            <>
                                <DragAndDrop data={props.data} />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

function Disconnected(props) {
    const { img_arrow } = props.data.img;
    const { userUID, userFirestoreData } = props;
    const { getSubscriptions } = props.data.fn;
    const { subscriptionId } = props.data.subscriptionInfo;
    const [mode, setMode] = useState(userFirestoreData?.updateMode || "simple");
    return (
        <>
            <div class="settings-container-outer disconnected">
                <div class="settings-container-inner">
                    <div class="data-panel-settings settings-cargr">
                        <div class="settings-head">
                            <div class="settings-heading">Ρυθμίσεις Car</div>
                            <div class="settings-subheading">Ο λογαριασμός car αποσυνδέθηκε. Ελέγξτε τον λόγο και επανασυνδέστε τον παρακάτω</div>
                        </div>
                        <div class="settings-group account-status-connected account-status-cred">
                            <div class="settings-group-head">
                                <div class="settings-group-heading">Σύνδεση</div>
                                <div class="settings-group-sub-heading">Επεξεργαστείτε την σύνδεση του λογαριασμού car με την πλατφόρμα</div>
                            </div>
                            <div class="settings-group-body">
                                <div class="settings-item account-connection-status">
                                    <div class="settings-item-heading">Κατάσταση</div>
                                    <div class="settings-item-options">
                                        <div class="settings-item-option">
                                            <div class="settings-item-option-action">
                                                <div style={{ "margin-top": "3px", "width": "10px", "height": "10px", "border-radius": "5px", "background-color": "orange" }}></div>
                                            </div>
                                            <div class="settings-item-option-head">
                                                <div class="settings-item-option-heading">Αποσυνδέθηκε</div>
                                                <div class="settings-item-option-subheading">Ο λογαριασμός αποσυνδέθηκε επειδή αλλάξατε το όνομα χρήστη ή τον κωδικό πρόσβασης στο car. Οι αυτόματες ανανεώσεις έχουν σταματήσει να λειτουργούν. Η συνδρομή σας έχει τεθεί σε παύση και δεν χρεώνεστε. Παρακαλώ επανασυνδεθείτε</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="settings-item account-status-connected account-status-cred">
                                    <div class="settings-item-heading">Επεξεργασία σύνδεσης</div>
                                    <div class="settings-item-options">
                                        <div class="settings-item-option wrap-768">
                                            <div class="settings-item-option-action">
                                                <Link to="/car-connect">
                                                    <div class="settings-button">Επανασύνδεση <img src={img_arrow} alt="" /></div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <ChooseMode data={{ mode, setMode, userUID }} TODO: REACTIVATE IT /> */}
                        {mode === "simple" ? (
                            // Simple Mode
                            <>
                                <UpdateLimitAndKeyword data={props.data} />
                                <ChooseCategory data={props.data} />
                            </>
                        ) : (
                            // Andavced Mode
                            <>
                                <DragAndDrop data={props.data} />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

function ChooseMode(props) {
    const { mode, setMode, userUID } = props.data;

    function toggleButton(clickedButton) {
        setMode(clickedButton);
        let otherButton;
        if (clickedButton === "simple") {
            clickedButton = document.querySelector("#mode-buttons > .simple");
            otherButton = document.querySelector("#mode-buttons > .advanced");
            db.collection('users').doc(userUID).set({ updateMode: "simple" }, { merge: true });
        } else if (clickedButton === "advanced") {
            clickedButton = document.querySelector("#mode-buttons > .advanced");
            otherButton = document.querySelector("#mode-buttons > .simple");
            db.collection('users').doc(userUID).set({ updateMode: "advanced" }, { merge: true });
        }

        // Remove the "inactive" class from the clicked button
        clickedButton.classList.remove('inactive');

        // Add the "inactive" class to the other button
        otherButton.classList.add('inactive');
    }

    return (
        <>
            <div class="settings-group account-status-connected account-status-cred">
                <div class="settings-group-head">
                    <div class="settings-group-heading">Mode ανανεώσεων</div>
                    <div class="settings-group-sub-heading">Επιλέξτε το mode που θα γίνονται οι ανανεώσεις</div>
                </div>
                <div class="settings-group-body">
                    <div class="settings-item">
                        <div id="mode-buttons" class="settings-item-heading">
                            {mode === "simple" ? (
                                <>
                                    <div class="settings-button portal blue simple" onClick={() => toggleButton("simple")}>Απλό</div>
                                    <div class="settings-button portal blue advanced inactive" onClick={() => toggleButton("advanced")}>Προχωρημένο</div>
                                </>
                            ) : (
                                <>
                                    <div class="settings-button portal blue simple inactive" onClick={() => toggleButton("simple")}>Απλό</div>
                                    <div class="settings-button portal blue advanced" onClick={() => toggleButton("advanced")}>Προχωρημένο</div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function UpdateLimitAndKeyword(props) {
    const { userFirestoreData, userUID } = props.data;
    const updatePrerences = userFirestoreData?.updatePrerences;
    const updateHourUserChoice = (userFirestoreData?.updatePrerences?.nextScheduledUpdate_hourUserChoice || userFirestoreData?.updatePrerences?.nextScheduledUpdate_hourUserChoice === 0) && userFirestoreData?.updatePrerences?.nextScheduledUpdate_hourUserChoice;
    const updateHourUserChoice_text = ((updateHourUserChoice || updateHourUserChoice === 0) && (updateHourUserChoice < 10 && `0${updateHourUserChoice}:00` || `${updateHourUserChoice}:00`) ) || "";
    const updateLimit = userFirestoreData?.updatePrerences?.numberOfAdsToUpdate || "";
    const keywordPhrase = userFirestoreData?.updatePrerences?.queryUrlParameters?.keyword || "";

    return (
        <>
            <div class="settings-group account-status-connected account-status-cred">
                <div class="settings-group-head">
                    <div class="settings-group-heading">Επιλογές ανανεώσεων</div>
                    <div class="settings-group-sub-heading">Επιλογές των ημερήσιων ανανεώσεων</div>
                </div>
                <div class="settings-group-body">
                    <div class="settings-item">
                        <div class="settings-item-heading">Ώρα ανανέωσης</div>
                        <div class="settings-item-options">
                            <div class="settings-item-option wrap-768 tax-input" style={{ "alignItems": "center" }}>
                                <div class="settings-item-option-action">
                                    <input type="number" id="updateTime" placeholder="Εισάγετε ώρα (0-23)" min="0" max="23"/>
                                </div>
                                {(() => {
                                    if (updateHourUserChoice || updateHourUserChoice == 0) {
                                        return (
                                            <>
                                                <div class="settings-item-option-head account-status-connected" >
                                                    <div class="settings-item-option-subheading">{updateHourUserChoice_text}</div>
                                                </div>
                                            </>
                                        );
                                    } else { return (<></>); }
                                })()}
                            </div>
                        </div>
                    </div>
                    <div class="settings-item">
                        <div class="settings-item-heading">Όριο ανανεώσεων</div>
                        <div class="settings-item-options">
                            <div class="settings-item-option wrap-768 tax-input" style={{ "alignItems": "center" }}>
                                <div class="settings-item-option-action">
                                    <input type="number" id="updateLimit" placeholder="Εισάγετε αριθμό" />
                                </div>
                                {(() => {
                                    if (updateLimit) {
                                        return (
                                            <>
                                                <div class="settings-item-option-head account-status-connected" >
                                                    <div class="settings-item-option-subheading">{updateLimit}</div>
                                                </div>
                                            </>
                                        );
                                    } else { return (<></>); }
                                })()}
                            </div>
                        </div>
                    </div>
                    <div class="settings-item">
                        <div class="settings-item-heading">Λέξη κλειδί</div>
                        <div class="settings-item-options">
                            <div class="settings-item-option wrap-768 tax-input" style={{ "alignItems": "center" }}>
                                <div class="settings-item-option-action">
                                    <input type="text" id="keyword" placeholder="Πληκτρολογήστε" />
                                </div>
                                {(() => {
                                    if (keywordPhrase) {
                                        return (
                                            <>
                                                <div class="settings-item-option-head account-status-connected" >
                                                    <div class="settings-item-option-subheading">{keywordPhrase}</div>
                                                </div>
                                            </>
                                        );
                                    } else { return (<></>); }
                                })()}
                            </div>
                        </div>
                    </div>
                    <div class="settings-item">
                        <div class="settings-item-heading simple-settings-buttons">
                            <div class="settings-button portal blue" onClick={() => { saveUpdateTime(userUID, updatePrerences); saveUpdateLimit(userUID, updatePrerences); saveKeyword(userUID, updatePrerences); }}>Αποθήκευση</div>
                            {updatePrerences.queryUrlParameters.keyword ? (
                                <>
                                    <div class="settings-button portal delete-keyword-button" onClick={() => removeKeyword(userUID, updatePrerences)}>Αφαίρεση λέξης</div>
                                </>
                            ) : (<></>)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function ChooseCategory(props) {
    const { userFirestoreData, userUID } = props.data;
    const updatePrerences = userFirestoreData?.updatePrerences;

    let mainCategory = userFirestoreData?.updatePrerences?.queryUrlParameters?.mainCategory;
    if (!mainCategory) {
        mainCategory = "antallaktika_aksesoyar";
        db.collection('users').doc(userUID).set({
            updatePrerences: {
                numberOfAdsToUpdate: 200,
                queryUrlParameters: {
                    mainCategory,
                    condition: "all",
                },
            }
        }, { merge: true });
    }

    return (
        <>
            <div class="settings-group account-status-connected account-status-cred">
                <div class="settings-group-head">
                    <div class="settings-group-heading">Κατηγορία</div>
                    <div class="settings-group-sub-heading">Επιλέξτε την κατηγορία αγγελιών που θα ανανεώνεται. Επιλέχθηκε:
                        <b>

                            {
                                (() => {
                                    const categoryId = mainCategory || "";
                                    let category = "";
                                    if (categoryId === "antallaktika_aksesoyar") { category = " Ανταλλακτικα & Αξεσουάρ" }
                                    else if (categoryId === "xyma") { category = " Μικρές αγγελίες" }
                                    else if (categoryId === "aytokinita") { category = " Αυτοκίνητα" }
                                    else if (categoryId === "motosykletes") { category = " Μοτοσυκλέτες" }
                                    else if (categoryId === "skafi") { category = " Σκάφη" }
                                    else if (categoryId === "podilata") { category = " Ποδήλατα" }
                                    else if (categoryId === "fortigaEos7_5t") { category = " Φορτηγά έως 7.5τ" }
                                    else if (categoryId === "fortigaAnoTon7_5t") { category = " Φορτηγά άνω των 7.5τ" }
                                    else if (categoryId === "leoforeia") { category = " Λεωφορεία" }
                                    else if (categoryId === "mixanimata") { category = " Μηχανήματα" }
                                    else if (categoryId === "traktores") { category = " Τράκτορες" }
                                    else if (categoryId === "epikathimena") { category = " Επικαθήμενα" }
                                    else if (categoryId === "georgika") { category = " Γεωργικά" }
                                    else if (categoryId === "klark") { category = " Κλάρκ" }
                                    else if (categoryId === "rymoylkes_treiler") { category = " Ρυμούλκες/τρέιλερ" }
                                    else if (categoryId === "taksi") { category = " Ταξί" }
                                    else if (categoryId === "troxospita") { category = " Τροχόσπιτα" }
                                    else if (categoryId === "aytokinoymena") { category = " Αυτοκινούμενα" }
                                    else if (categoryId === "tilekateythynomena") { category = " Τηλεκατευθυνόμενα" }
                                    else if (categoryId === "goKart") { category = " Go kart" }
                                    else if (categoryId === "thalassiaSpor") { category = " Θαλάσσια σπόρ" }
                                    else if (categoryId === "xeimerinaSpor") { category = " Χειμερινά σπόρ" }
                                    else if (categoryId === "aerathlima") { category = " Αεράθλημα" }
                                    else if (categoryId === "aggeliesErgasias") { category = " Αγγελίες Εργασίας" }
                                    else if (categoryId === "enoikiaseis") { category = " Ενοικιάσεις" }

                                    return category
                                })()
                            }
                        </b>
                    </div>
                </div>
                <div class="">
                    <form class="cargr-form">
                        <input type="radio" id="antallaktika_aksesoyar" name="choice" value="antallaktika_aksesoyar" defaultChecked={mainCategory === "antallaktika_aksesoyar"} />
                        <label onClick={() => setDb(userUID, "antallaktika_aksesoyar", updatePrerences)} for="antallaktika_aksesoyar">Ανταλλακτικα & Αξεσουάρ</label><br />
                        <input type="radio" id="xyma" name="choice" value="xyma" defaultChecked={mainCategory === "xyma"} />
                        <label onClick={() => setDb(userUID, "xyma", updatePrerences)} for="xyma">Μικρές αγγελίες</label><br />
                        <input type="radio" id="aytokinita" name="choice" value="aytokinita" defaultChecked={mainCategory === "aytokinita"} />
                        <label onClick={() => setDb(userUID, "aytokinita", updatePrerences)} for="aytokinita">Αυτοκίνητα</label><br />
                        <input type="radio" id="motosykletes" name="choice" value="motosykletes" defaultChecked={mainCategory === "motosykletes"} />
                        <label onClick={() => setDb(userUID, "motosykletes", updatePrerences)} for="motosykletes">Μοτοσυκλέτες</label><br />
                        <input type="radio" id="skafi" name="choice" value="skafi" defaultChecked={mainCategory === "skafi"} />
                        <label onClick={() => setDb(userUID, "skafi", updatePrerences)} for="skafi">Σκάφη</label><br />
                        <input type="radio" id="podilata" name="choice" value="podilata" defaultChecked={mainCategory === "podilata"} />
                        <label onClick={() => setDb(userUID, "podilata", updatePrerences)} for="podilata">Ποδήλατα</label><br />
                        <input type="radio" id="fortigaEos7_5t" name="choice" value="fortigaEos7_5t" defaultChecked={mainCategory === "fortigaEos7_5t"} />
                        <label onClick={() => setDb(userUID, "fortigaEos7_5t", updatePrerences)} for="fortigaEos7_5t">Φορτηγά έως 7.5τ</label><br />
                        <input type="radio" id="fortigaAnoTon7_5t" name="choice" value="fortigaAnoTon7_5t" defaultChecked={mainCategory === "fortigaAnoTon7_5t"} />
                        <label onClick={() => setDb(userUID, "fortigaAnoTon7_5t", updatePrerences)} for="fortigaAnoTon7_5t">Φορτηγά άνω των 7.5τ</label><br />
                        <input type="radio" id="leoforeia" name="choice" value="leoforeia" defaultChecked={mainCategory === "leoforeia"} />
                        <label onClick={() => setDb(userUID, "leoforeia", updatePrerences)} for="leoforeia">Λεωφορεία</label><br />
                        <input type="radio" id="mixanimata" name="choice" value="mixanimata" defaultChecked={mainCategory === "mixanimata"} />
                        <label onClick={() => setDb(userUID, "mixanimata", updatePrerences)} for="mixanimata">Μηχανήματα</label><br />
                        <input type="radio" id="traktores" name="choice" value="traktores" defaultChecked={mainCategory === "traktores"} />
                        <label onClick={() => setDb(userUID, "traktores", updatePrerences)} for="traktores">Τράκτορες</label><br />
                        <input type="radio" id="epikathimena" name="choice" value="epikathimena" defaultChecked={mainCategory === "epikathimena"} />
                        <label onClick={() => setDb(userUID, "epikathimena", updatePrerences)} for="epikathimena">Επικαθήμενα</label><br />
                        <input type="radio" id="georgika" name="choice" value="georgika" defaultChecked={mainCategory === "georgika"} />
                        <label onClick={() => setDb(userUID, "georgika", updatePrerences)} for="georgika">Γεωργικά</label><br />
                        <input type="radio" id="klark" name="choice" value="klark" defaultChecked={mainCategory === "klark"} />
                        <label onClick={() => setDb(userUID, "klark", updatePrerences)} for="klark">Κλάρκ</label><br />
                        <input type="radio" id="rymoylkes_treiler" name="choice" value="rymoylkes_treiler" defaultChecked={mainCategory === "rymoylkes_treiler"} />
                        <label onClick={() => setDb(userUID, "rymoylkes_treiler", updatePrerences)} for="rymoylkes_treiler">Ρυμούλκες/τρέιλερ</label><br />
                        <input type="radio" id="taksi" name="choice" value="taksi" defaultChecked={mainCategory === "taksi"} />
                        <label onClick={() => setDb(userUID, "taksi", updatePrerences)} for="taksi">Ταξί</label><br />
                        <input type="radio" id="troxospita" name="choice" value="troxospita" defaultChecked={mainCategory === "troxospita"} />
                        <label onClick={() => setDb(userUID, "troxospita", updatePrerences)} for="troxospita">Τροχόσπιτα</label><br />
                        <input type="radio" id="aytokinoymena" name="choice" value="aytokinoymena" defaultChecked={mainCategory === "aytokinoymena"} />
                        <label onClick={() => setDb(userUID, "aytokinoymena", updatePrerences)} for="aytokinoymena">Αυτοκινούμενα</label><br />
                        <input type="radio" id="tilekateythynomena" name="choice" value="tilekateythynomena" defaultChecked={mainCategory === "tilekateythynomena"} />
                        <label onClick={() => setDb(userUID, "tilekateythynomena", updatePrerences)} for="tilekateythynomena">Τηλεκατευθυνόμενα</label><br />
                        <input type="radio" id="goKart" name="choice" value="goKart" defaultChecked={mainCategory === "goKart"} />
                        <label onClick={() => setDb(userUID, "goKart", updatePrerences)} for="goKart">Go kart</label><br />
                        <input type="radio" id="thalassiaSpor" name="choice" value="thalassiaSpor" defaultChecked={mainCategory === "thalassiaSpor"} />
                        <label onClick={() => setDb(userUID, "thalassiaSpor", updatePrerences)} for="thalassiaSpor">Θαλάσσια σπόρ</label><br />
                        <input type="radio" id="xeimerinaSpor" name="choice" value="xeimerinaSpor" defaultChecked={mainCategory === "xeimerinaSpor"} />
                        <label onClick={() => setDb(userUID, "xeimerinaSpor", updatePrerences)} for="xeimerinaSpor">Χειμερινά σπόρ</label><br />
                        <input type="radio" id="aerathlima" name="choice" value="aerathlima" defaultChecked={mainCategory === "aerathlima"} />
                        <label onClick={() => setDb(userUID, "aerathlima", updatePrerences)} for="aerathlima">Αεράθλημα</label><br />
                        <input type="radio" id="aggeliesErgasias" name="choice" value="aggeliesErgasias" defaultChecked={mainCategory === "aggeliesErgasias"} />
                        <label onClick={() => setDb(userUID, "aggeliesErgasias", updatePrerences)} for="aggeliesErgasias">Αγγελίες Εργασίας</label><br />
                        <input type="radio" id="enoikiaseis" name="choice" value="enoikiaseis" defaultChecked={mainCategory === "enoikiaseis"} />
                        <label onClick={() => setDb(userUID, "enoikiaseis", updatePrerences)} for="enoikiaseis">Ενοικιάσεις</label><br />
                    </form>
                </div>
            </div>
        </>
    )
}

function DragAndDrop(props) {
    const { img_grip, img_move_up, img_move_down } = props.data.img;
    const { userFirestoreData, userUID } = props.data;
    const updatePrerences = userFirestoreData?.updatePrerences;
    const keywordPhrase = userFirestoreData?.updatePrerences?.queryUrlParameters?.keyword || "";

    const [items, setItems] = useState(userFirestoreData?.updatePrerences_advanced?.orders || []);
    if (!userFirestoreData?.updatePrerences_advanced?.orders) setAndSyncItems([]);

    function setAndSyncItems(newItems) {
        setItems(newItems);
        userFirestoreData.updatePrerences_advanced = { ...userFirestoreData.updatePrerences_advanced, orders: newItems };
        db.collection('users').doc(userUID).set({
            updatePrerences_advanced: {
                orders: newItems
            }
        }, { merge: true });
    }

    const [newItemType, setNewItemType] = useState('withText');
    const [newItemText, setNewItemText] = useState('');
    const [newItemNumber, setNewItemNumber] = useState('');
    const [newItemOption, setNewItemOption] = useState('antallaktika_aksesoyar');

    const handleDragStart = (e, index) => {
        e.dataTransfer.setData('index', index);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e, newIndex) => {
        e.preventDefault();
        const oldIndex = e.dataTransfer.getData('index');
        const newItems = [...items];
        const [draggedItem] = newItems.splice(oldIndex, 1);
        newItems.splice(newIndex, 0, draggedItem);
        setAndSyncItems(newItems);
    };

    const moveItemUp = (index) => {
        if (index > 0) {
            const newItems = [...items];
            const temp = newItems[index];
            newItems[index] = newItems[index - 1];
            newItems[index - 1] = temp;
            setAndSyncItems(newItems);
        }
    };

    const moveItemDown = (index) => {
        if (index < items.length - 1) {
            const newItems = [...items];
            const temp = newItems[index];
            newItems[index] = newItems[index + 1];
            newItems[index + 1] = temp;
            setAndSyncItems(newItems);
        }
    };

    const handleAddItem = () => {
        if (newItemType === 'withText' && newItemOption && newItemNumber > 0) {
            setAndSyncItems([
                ...items,
                {
                    selectedOption: newItemOption,
                    number: newItemNumber,
                    text: newItemText,
                },
            ]);
            setNewItemText('');
            setNewItemNumber('');
            setNewItemOption('antallaktika_aksesoyar');
        } else if (newItemType === 'onlyNumber' && newItemNumber > 0) {
            setAndSyncItems([
                ...items,
                {
                    selectedOption: '',
                    number: newItemNumber,
                    text: '',
                },
            ]);
            setNewItemText('');
            setNewItemNumber('');
            setNewItemOption('antallaktika_aksesoyar');
        }
    };

    const handleRemoveItem = (index) => {
        const newItems = [...items];
        newItems.splice(index, 1);
        setAndSyncItems(newItems);
    };

    const categories = {
        "antallaktika_aksesoyar": "Ανταλλακτικα & Αξεσουάρ",
        "xyma": "Μικρές αγγελίες",
        "aytokinita": "Αυτοκίνητα",
        "motosykletes": "Μοτοσυκλέτες",
        "skafi": "Σκάφη",
        "podilata": "Ποδήλατα",
        "fortigaEos7_5t": "Φορτηγά έως 7.5τ",
        "fortigaAnoTon7_5t": "Φορτηγά άνω των 7.5τ",
        "leoforeia": "Λεωφορεία",
        "mixanimata": "Μηχανήματα",
        "traktores": "Τράκτορες",
        "epikathimena": "Επικαθήμενα",
        "georgika": "Γεωργικά",
        "klark": "Κλάρκ",
        "rymoylkes_treiler": "Ρυμούλκες/τρέιλερ",
        "taksi": "Ταξί",
        "troxospita": "Τροχόσπιτα",
        "aytokinoymena": "Αυτοκινούμενα",
        "tilekateythynomena": "Τηλεκατευθυνόμενα",
        "goKart": "Go kart",
        "thalassiaSpor": "Θαλάσσια σπόρ",
        "xeimerinaSpor": "Χειμερινά σπόρ",
        "aerathlima": "Αεράθλημα",
        "aggeliesErgasias": "Αγγελίες Εργασίας",
        "enoikiaseis": "Ενοικιάσεις",
    }

    // Add a state variable to store the sum
    const [totalSum, setTotalSum] = useState(0);

    // Function to calculate the sum of item numbers
    const calculateTotalSum = () => {
        const sum = items.reduce((acc, item) => {
            if (item.selectedOption !== '') {
                return acc + item.number;
            }
            return acc;
        }, 0);
        setTotalSum(sum);
    };

    // Call the calculateTotalSum function whenever the items array changes
    React.useEffect(() => {
        calculateTotalSum();
    }, [items]);

    return (
        <div class="settings-group account-status-connected account-status-cred">
            <div class="settings-group-head">
                <div class="settings-group-heading">Προχωρημένες ανανεώσεις (Υπό κατασκευή)</div>
                <div class="settings-group-sub-heading">Παρακάτω μπορείτε να κάνετε προχωρημένες ρυθμίσεις ανανεώσεων. Σύνολο ανανεώσεων: <b>{totalSum}</b>
                </div>
            </div>
            <div class="settings-group-body">
                <div class="drag-n-drop">
                    <ul>
                        {items.map((item, index) => (
                            <li
                                key={index}
                                draggable
                                onDragStart={(e) => handleDragStart(e, index)}
                                onDragOver={handleDragOver}
                                onDrop={(e) => handleDrop(e, index)}
                                class={item.selectedOption !== '' ? ("new-item-text") : ("new-item-number")}
                            >
                                {item.selectedOption !== '' ? (
                                    <>
                                        <div class="arrow-buttons">
                                            <button onClick={() => moveItemUp(index)}><img src={img_move_up} alt="aa" /></button>
                                            <button onClick={() => moveItemDown(index)}><img src={img_move_down} alt="aa" /></button>
                                        </div>
                                        <img class="grip" src={img_grip} alt="" />
                                        <div class="li-content">
                                            <div>
                                                <span>Κατηγορία: </span>
                                                <span>{categories[item.selectedOption]}</span>
                                            </div>
                                            <div>
                                                <span>Αριθμός ανανεώσεων: </span>
                                                <span>{item.number}</span>
                                            </div>
                                            <div>
                                                <span>Λέξη Κλειδί: </span>
                                                <span>{item.text}</span>
                                            </div>
                                        </div>
                                        <button class="remove" onClick={() => handleRemoveItem(index)}>Διαγραφή</button>
                                    </>
                                ) : (
                                    <>
                                        <div class="arrow-buttons">
                                            <button onClick={() => moveItemUp(index)}><img src={img_move_up} alt="aa" /></button>
                                            <button onClick={() => moveItemDown(index)}><img src={img_move_down} alt="aa" /></button>
                                        </div>
                                        <img class="grip" src={img_grip} alt="" />
                                        <div class="li-content wait">
                                            <div>
                                                <span>Αναμονή: </span>
                                                <span>
                                                    <span>{item.number}</span>
                                                    <span> min</span>
                                                </span>
                                            </div>
                                        </div>
                                        <button class="remove" onClick={() => handleRemoveItem(index)}>Διαγραφή</button>
                                    </>
                                )}
                            </li>
                        ))}
                    </ul>
                    <div class="add">
                        <label>
                            <select value={newItemType} onChange={(e) => setNewItemType(e.target.value)}>
                                <option value="withText">Ανανέωση</option>
                                <option value="onlyNumber">Αναμονή (λεπτά)</option>
                            </select>
                        </label>
                        {newItemType === 'withText' && (
                            <>
                                <select
                                    value={newItemOption}
                                    onChange={(e) => setNewItemOption(e.target.value)}
                                >
                                    <option value="antallaktika_aksesoyar">Ανταλλακτικα & Αξεσουάρ</option>
                                    <option value="xyma">Μικρές αγγελίες</option>
                                    <option value="aytokinita">Αυτοκίνητα</option>
                                    <option value="motosykletes">Μοτοσυκλέτες</option>
                                    <option value="skafi">Σκάφη</option>
                                    <option value="podilata">Ποδήλατα</option>
                                    <option value="fortigaEos7_5t">Φορτηγά έως 7.5τ</option>
                                    <option value="fortigaAnoTon7_5t">Φορτηγά άνω των 7.5τ</option>
                                    <option value="leoforeia">Λεωφορεία</option>
                                    <option value="mixanimata">Μηχανήματα</option>
                                    <option value="traktores">Τράκτορες</option>
                                    <option value="epikathimena">Επικαθήμενα</option>
                                    <option value="georgika">Γεωργικά</option>
                                    <option value="klark">Κλάρκ</option>
                                    <option value="rymoylkes_treiler">Ρυμούλκες/τρέιλερ</option>
                                    <option value="taksi">Ταξί</option>
                                    <option value="troxospita">Τροχόσπιτα</option>
                                    <option value="aytokinoymena">Αυτοκινούμενα</option>
                                    <option value="tilekateythynomena">Τηλεκατευθυνόμενα</option>
                                    <option value="goKart">Go kart</option>
                                    <option value="thalassiaSpor">Θαλάσσια σπόρ</option>
                                    <option value="xeimerinaSpor">Χειμερινά σπόρ</option>
                                    <option value="aerathlima">Αεράθλημα</option>
                                    <option value="aggeliesErgasias">Αγγελίες Εργασίας</option>
                                    <option value="enoikiaseis">Ενοικιάσεις</option>
                                </select>
                                <input
                                    type="number"
                                    value={newItemNumber}
                                    onChange={(e) => setNewItemNumber(parseInt(e.target.value, 10))}
                                    placeholder="Αριθμός ανανεώσεων"
                                />
                                <input
                                    type="text"
                                    value={newItemText}
                                    onChange={(e) => setNewItemText(e.target.value)}
                                    placeholder="Λέξη κλειδί"
                                />
                            </>
                        )}
                        {newItemType === 'onlyNumber' && (
                            <>
                                <div class="numstl">
                                    <input
                                        type="number"
                                        value={newItemNumber}
                                        onChange={(e) => setNewItemNumber(parseInt(e.target.value, 10))}
                                        placeholder="Λεπτά αναμονής"
                                    />
                                </div>
                            </>
                        )}
                        <button onClick={handleAddItem}>Add</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

async function saveKeyword(uid, updatePrerences) {
    const keywordPhrase = document.getElementById("keyword").value || false;

    if (keywordPhrase) {
        updatePrerences.queryUrlParameters.keyword = keywordPhrase;
        await db.collection('users').doc(uid).set({ updatePrerences }, { merge: true });
        document.getElementById("keyword").value = "";
    }
};

async function removeKeyword(uid, updatePrerences) {
    const keywordPhrase = false;

    updatePrerences.queryUrlParameters.keyword = keywordPhrase;
    await db.collection('users').doc(uid).set({ updatePrerences }, { merge: true });
    document.getElementById("keyword").value = "";
};

async function saveUpdateLimit(uid, updatePrerences) {
    const updateLimit = document.getElementById("updateLimit").value || false;

    if (updateLimit > 0) {
        updatePrerences.numberOfAdsToUpdate = Number(updateLimit);
        await db.collection('users').doc(uid).set({ updatePrerences }, { merge: true });
    }
    document.getElementById("updateLimit").value = "";
};

async function saveUpdateTime(uid, updatePrerences) {
    const updateTime = document.getElementById("updateTime").value || false;

    if (updateTime >= 0 && updateTime <=23) {
        updatePrerences.nextScheduledUpdate_hourUserChoice = Number(updateTime);
        await db.collection('users').doc(uid).set({ updatePrerences }, { merge: true });
    }
    document.getElementById("updateTime").value = "";
};

async function disconnect(userUID, getSubscriptions, subscriptionId) {
    db.collection('users').doc(userUID).set({
        cargrConnectionStatus: false,
        cargrCredentials: {}
    }, { merge: true });

    await fetchFromAPI('subscriptions/pause/' + subscriptionId, { method: 'PATCH' });
    await getSubscriptions();
}

function setDb(userUID, value, updatePrerences) {
    db.collection('users').doc(userUID).set({
        updatePrerences: {
            numberOfAdsToUpdate: updatePrerences?.numberOfAdsToUpdate || 200,
            queryUrlParameters: {
                mainCategory: value,
                condition: "all",
            },
        }
    }, { merge: true });
}

/// Under here is a sample for another customer
// function PBP(props) {
//     const { userUID, userFirestoreData } = props;
//     const { getSubscriptions } = props.data.fn;
//     const { subscriptionId } = props.data.subscriptionInfo;

//     const [mode, setMode] = useState(userFirestoreData?.updateMode || "simple");

//     return (
//         <>
//             <div class="settings-container-outer">
//                 <div class="settings-container-inner">
//                     <div class="data-panel-settings settings-cargr">
//                         <div class="settings-head">
//                             <div class="settings-heading">Παραγωγή Excel</div>
//                         </div>
//                         <UploadFiles data={props.data} />
//                         <DownloadFile data={{ mode, setMode, userUID }} />
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }

// function UploadFiles(props) {
//     const { userFirestoreData, userUID } = props.data;
//     const updatePrerences = userFirestoreData?.updatePrerences;
//     const updateLimit = userFirestoreData?.updatePrerences?.numberOfAdsToUpdate || "";
//     const keywordPhrase = userFirestoreData?.updatePrerences?.queryUrlParameters?.keyword || "";

//     return (
//         <>
//             <div class="settings-group account-status-connected account-status-cred">
//                 <div class="settings-group-head">
//                     <div class="settings-group-heading">Upload Files</div>
//                     <div class="settings-group-sub-heading">Επιλέξτε το αντίστοιχο αρχείο προμηθευτή για upload</div>
//                 </div>
//                 <div class="settings-group-body">
//                     <div class="settings-item">
//                         <div class="settings-item-heading">Supplier A</div>
//                         <div class="settings-item-heading simple-settings-buttons">
//                             <div class="settings-button portal blue" onClick={() => { saveUpdateLimit(userUID, updatePrerences); saveKeyword(userUID, updatePrerences); }}>Επιλέξτε Αρχείο</div>
//                         </div>
//                     </div>
//                     <div class="settings-item">
//                         <div class="settings-item-heading">Supplier B</div>
//                         <div class="settings-item-heading simple-settings-buttons">
//                             <div class="settings-button portal blue" onClick={() => { saveUpdateLimit(userUID, updatePrerences); saveKeyword(userUID, updatePrerences); }}>Επιλέξτε Αρχείο</div>
//                         </div>
//                     </div>
//                     <div class="settings-item">
//                         <div class="settings-item-heading">Supplier C</div>
//                         <div class="settings-item-heading simple-settings-buttons">
//                             <div class="settings-button portal blue" onClick={() => { saveUpdateLimit(userUID, updatePrerences); saveKeyword(userUID, updatePrerences); }}>Επιλέξτε Αρχείο</div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }

// function DownloadFile(props) {
//     const { mode, setMode, userUID } = props.data;

//     function toggleButton(clickedButton) {
//         setMode(clickedButton);
//         let otherButton;
//         if (clickedButton === "simple") {
//             clickedButton = document.querySelector("#mode-buttons > .simple");
//             otherButton = document.querySelector("#mode-buttons > .advanced");
//             db.collection('users').doc(userUID).set({ updateMode: "simple" }, { merge: true });
//         } else if (clickedButton === "advanced") {
//             clickedButton = document.querySelector("#mode-buttons > .advanced");
//             otherButton = document.querySelector("#mode-buttons > .simple");
//             db.collection('users').doc(userUID).set({ updateMode: "advanced" }, { merge: true });
//         }

//         // Remove the "inactive" class from the clicked button
//         clickedButton.classList.remove('inactive');

//         // Add the "inactive" class to the other button
//         otherButton.classList.add('inactive');
//     }

//     return (
//         <>
//             <div class="settings-group account-status-connected account-status-cred">
//                 <div class="settings-group-head">
//                     <div class="settings-group-heading">Download File</div>
//                     <div class="settings-group-sub-heading">Πατήστε το παρακάτω κουμπί για να λάβετε το τελικό αρχείο Excel</div>
//                 </div>
//                 <div class="settings-group-body">
//                     <div class="settings-item">
//                         <div class="settings-item-heading simple-settings-buttons">
//                             <div class="settings-button portal blue" >Download File</div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }