import './css/normalize.css';
import './css/config.css';
import './css/login.css'
import './css/nav.css'
import './css/loading.css'
import './css/panel.css'
import './css/home.css'
import './css/cargr.css'
import './css/subscription.css'
import './css/login-cargr.css'
import './css/drag-and-drop-list.css'

import img_loading from "./pages/img/rolling.svg";
import img_loading_white from "./pages/img/rolling_white.svg";
import img_menu from './pages/img/menu-burger.svg';
import img_logout from './pages/img/logout.png';
import img_logo from './pages/img/logo.png';
import img_arrow from "./pages/img/arrow-left-light.svg";
import img_check from "./pages/img/check.png";
import img_morning from "./pages/img/morning.png";
import img_evening from "./pages/img/evening.png";
import img_arrow_blue from "./pages/img/arrow-left-blue.svg";
import img_arrow_black from "./pages/img/arrow-left-dark.svg";
import img_clock from "./pages/img/clock.png";
import img_reload from "./pages/img/reload.png";
import img_user from "./pages/img/user.png";
import img_google from "./pages/img/google_logo.svg";
import img_grip from "./pages/img/grip-dots.svg";
import img_move_up from "./pages/img/move-up.svg";
import img_move_down from "./pages/img/move-down.svg";
import img_login_background from "./pages/img/loginBackground.webp";

import React, { Suspense, useState, useEffect } from 'react';

import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import Login from './pages/Login';
import Cargr from './pages/Cargr';
import CarConnect from './pages/CarConnect';
import Home from './pages/Home';
import Subscription, { Update } from './pages/Subscription';
import { AuthCheck, AuthProvider, useFirebaseApp, useUser } from 'reactfire';
import { getAuth } from 'firebase/auth';
import { auth, db } from './firebase';
import { fetchFromAPI } from './helpers';
import { useStripe } from '@stripe/react-stripe-js';
import Logout_debug from './pages/Logout-debug';

export default function App() {
  const app = useFirebaseApp();
  const auth = getAuth(app);

  const [dataReady_userFirestoreData, setDataReady_userFirestoreData] = useState(false);
  const [dataReady_subscriptions, setDataReady_subscriptions] = useState(false);
  function LoadingScreenMain() {
    if (dataReady_userFirestoreData === false || dataReady_subscriptions === false) {
      return (
        <div id="loading" class="enabled" >
          <div class="wrap">
            <img src={img_loading} alt="" />
          </div>
          <div class="main screen"></div>
        </div>
      )
    } else {
      return (<div id="loading" class="disabled" ></div>)
    }
  }
  const loadingScreenData_Main = { dataReady_userFirestoreData, setDataReady_userFirestoreData, dataReady_subscriptions, setDataReady_subscriptions }

  return (
    <Router>
      <LoadingScreenMain />
      <AuthProvider sdk={auth}>
        {/* <Suspense fallback={'loading user'}> */}
        <AuthCheck fallback={<Login img_google={img_google} loadingScreenData_Main={loadingScreenData_Main} />}>
          <Content loadingScreenData_Main={loadingScreenData_Main} />
        </AuthCheck>
        {/* </Suspense> */}
      </AuthProvider>
    </Router>
  );
}

function Content(props) {
  const { setDataReady_userFirestoreData, setDataReady_subscriptions } = props.loadingScreenData_Main;
  const [loading, setLoading] = useState(false);

  function LoadingScreen() {
    if (loading) {
      return (
        <div id="loading" class="enabled" >
          <div class="wrap">
            <img src={img_loading_white} alt="" />
          </div>
          <div class="screen"></div>
        </div>
      )
    } else { return (<div id="loading" class="disabled" ></div>) }
  }

  const user = useUser();
  const userUID = user?.data?.uid;
  const [userFirestoreData, setUserFirestoreData] = useState({});
  useEffect(() => {
    (async () => {
      if (userUID) {
        db.collection('users').doc(userUID).onSnapshot(doc => setUserFirestoreData(doc.data()));
      }
      setDataReady_userFirestoreData(true);
    })()
  }, [userUID, setDataReady_userFirestoreData]);
  // useEffect(() => { getUserFirestoreData() }, [userUID]);
  // const getUserFirestoreData = async () => {
  //   if (userUID) {
  //     db.collection('users').doc(userUID).onSnapshot(doc => setUserFirestoreData(doc.data()))
  //   }
  //   setDataReady_userFirestoreData(true);
  // };

  const stripe = useStripe();
  const stripeCustomerId = userFirestoreData?.stripeCustomerId;
  const [subscriptions, setSubscriptions] = useState([]);
  useEffect(() => {
    (async () => {
      if (user) {
        const subs = await fetchFromAPI('subscriptions', { method: 'GET' });
        setSubscriptions(subs);
      }
      setDataReady_subscriptions(true);
    })()
  }, [user, setDataReady_subscriptions]);
  // useEffect(() => { getSubscriptions() }, [user]);
  const getSubscriptions = async () => {
    if (user) {
      const subs = await fetchFromAPI('subscriptions', { method: 'GET' });
      setSubscriptions(subs);
    }
    setDataReady_subscriptions(true);
  };

  const subscriptionInfo = {
    isSubscribed: !!subscriptions?.[0],
    isSubscriptionPaused: !!subscriptions?.[0]?.pause_collection,
    isSubscriptionPaid: !!userFirestoreData?.paymentStatus && userFirestoreData?.paymentStatus === "PAID",
    toBeCancelled: subscriptions?.[0]?.cancel_at_period_end,
    subscriptionId: subscriptions?.[0]?.id
  };

  if (subscriptionInfo?.isSubscribed) {
    const date = new Date(subscriptions?.[0]?.current_period_end * 1000);
    const day = date.getDate();
    let month = date.getMonth();
    switch (month) {
      case 0: month = "Ιαν"; break;
      case 1: month = "Φεβ"; break;
      case 2: month = "Μαρ"; break;
      case 3: month = "Απρ"; break;
      case 4: month = "Μαι"; break;
      case 5: month = "Ιουν"; break;
      case 6: month = "Ιουλ"; break;
      case 7: month = "Αυγ"; break;
      case 8: month = "Σεπ"; break;
      case 9: month = "Οκτ"; break;
      case 10: month = "Νοε"; break;
      case 11: month = "Δεκ"; break;
      default: month = "";
    }
    const year = date.getFullYear();
    subscriptionInfo.daySubEnds = day;
    subscriptionInfo.dateSubEnds = `${day} ${month} ${year}`;
  }

  if (subscriptionInfo?.isSubscribed) {
    const date = new Date(subscriptions?.[0]?.current_period_end * 1000);
    date.setUTCDate(date.getUTCDate() + 7);
    const day = date.getDate();
    let month = date.getMonth();
    switch (month) {
      case 0: month = "Ιαν"; break;
      case 1: month = "Φεβ"; break;
      case 2: month = "Μαρ"; break;
      case 3: month = "Απρ"; break;
      case 4: month = "Μαι"; break;
      case 5: month = "Ιουν"; break;
      case 6: month = "Ιουλ"; break;
      case 7: month = "Αυγ"; break;
      case 8: month = "Σεπ"; break;
      case 9: month = "Οκτ"; break;
      case 10: month = "Νοε"; break;
      case 11: month = "Δεκ"; break;
      default: month = "";
    }
    const year = date.getFullYear();
    subscriptionInfo.daySubEndsPlus7Days = day;
    subscriptionInfo.dateSubEndsPlus7Days = `${day} ${month} ${year}`;
  }

  const subscriptionNameId_index = {
    evening: {
      month: "evening-month-sub",
      year: "evening-year-sub"
    },
    morning: {
      month: "morning-month-sub",
      year: "morning-year-sub"
    }
  }

  const subscriptions_object = [{
    nameId: "evening-month-sub",
    productName: "Απογευματινό Πακέτο Ανανεώσεων",
    productId: "prod_OBSmCLFIVMMmR9",
    // productId: "prod_O0YOqUGo4CZaSC",
    priceId: "price_1NP5qvLhHA6SPddpb31GHIVb",
    // priceId: "price_1NEXHPLhHA6SPddp7nVak5Rm",
    recurringInterval: "month",
    unitAmount: "1500"
  }, {
    nameId: "evening-year-sub",
    productName: "Απογευματινό Πακέτο Ανανεώσεων",
    productId: "prod_OBSmCLFIVMMmR9",
    // productId: "prod_O0YOqUGo4CZaSC",
    priceId: "price_1NP5qvLhHA6SPddpodmEUCWk",
    // priceId: "price_1NEXHPLhHA6SPddprdAZrGHg",
    recurringInterval: "year",
    unitAmount: "15000"
  }, {
    nameId: "morning-month-sub",
    productName: "Πρωινό Πακέτο Ανανεώσεων",
    productId: "prod_OBSlAmhJhCKdEJ",
    // productId: "prod_O0YPW5CV6GxF9D",
    priceId: "price_1NP5pdLhHA6SPddpV3HHsaiz",
    // priceId: "price_1NEXIHLhHA6SPddpeKNDyvPz",
    recurringInterval: "month",
    unitAmount: "2500"
  }, {
    nameId: "morning-year-sub",
    productName: "Πρωινό Πακέτο Ανανεώσεων",
    productId: "prod_OBSlAmhJhCKdEJ",
    // productId: "prod_O0YPW5CV6GxF9D",
    priceId: "price_1NP5pdLhHA6SPddp7l2diw1l",
    // priceId: "price_1NEXIHLhHA6SPddpGecU2iLN",
    recurringInterval: "year",
    unitAmount: "25000"
  }];
  for (let dt of subscriptions_object) {
    if (dt.productId === userFirestoreData?.activeSubscription?.price?.product?.id) { dt["activeProduct"] = true }
    if (dt.priceId === userFirestoreData?.activeSubscription?.price?.id) {
      dt["activePrice"] = true;
      subscriptionInfo.active_subscription_object = dt;
    }
  }

  // TODO: For testing. Remove below
  // subscriptionInfo.isSubscribed = true;
  // subscriptionInfo.isSubscriptionPaused = true;
  // subscriptionInfo.toBeCancelled = true;
  // subscriptionInfo.isSubscriptionPaid = false;
  // userFirestoreData.cargrConnectionStatus = "err-cred"
  // userFirestoreData.cargrConnectionStatus = ""

  const data = {
    user,
    userUID,
    userFirestoreData,
    stripe,
    stripeCustomerId,
    subscriptions,
    subscriptions_object,
    subscriptionInfo,
    subscriptionNameId_index,
    fn: {
      getSubscriptions,
      setLoading
    },
    loadingScreenData_Main: props.loadingScreenData_Main,
    img: {
      img_loading,
      img_loading_white,
      img_menu,
      img_logout,
      img_logo,
      img_arrow,
      img_check,
      img_morning,
      img_evening,
      img_arrow_blue,
      img_arrow_black,
      img_clock,
      img_reload,
      img_google,
      img_grip,
      img_move_up,
      img_move_down,
      img_login_background
    }
  };

  return (
    <>
      <div>
        <LoadingScreen />
        <Nav data={data} />
        <RoutesList data={data} />
      </div>
    </>
  )
}

function Nav(props) {
  const { userFirestoreData, subscriptionInfo, user } = props.data;
  const { isSubscribed, isSubscriptionPaused, isSubscriptionPaid, toBeCancelled } = subscriptionInfo;
  const userDisplayName = user?.data?.displayName || "";
  const userHasPhoto = (user?.data?.photoURL && true) || false;
  const userPhoto = user?.data?.photoURL || img_logout;

  return (
    <nav id="nav-bar">
      <div class="sign-out-diag">
        <div class="sign-out-diag-inner">
          <div class="user">
            {(() => {
              if (userHasPhoto) {
                return (
                  <>
                    <img src={userPhoto} alt="" />
                  </>
                );
              }
            })()}
            <div class="username">{userDisplayName}</div>
          </div>
          <div class="line"></div>
          <div class="sign-out-btn" onClick={() => auth.signOut()} >Αποσύνδεση</div>
        </div>
      </div>
      <div class="diag-screen" onClick={() => { document.querySelector(".sign-out-diag").classList.toggle("expanded"); document.body?.classList?.remove('freeze') }}></div>
      <div class="nav-top">
        <div class="menu-img" onClick={() => { document.querySelector(".nav-mobile").classList.toggle("expanded") }}>
          <img src={img_menu} alt="" />
        </div>
        <img src={img_logo} alt="" class="first" />
        {/* <div class="first"></div> */}
        <ul className="navbar-nav">
          <li>
            <Link to="/">
              Αρχική
            </Link>
          </li>
          <li>
            <Link to="/car">
              Car
            </Link>
            {!userFirestoreData?.cargrConnectionStatus || userFirestoreData?.cargrConnectionStatus === "err-cred"
              ? (<div id="navbar-car-dot" style={{ "margin-left": "8px", "width": "8px", "height": "8px", "border-radius": "4px", "background-color": "orange" }}></div>)
              : userFirestoreData?.cargrConnectionStatus === "err-other"
                ? (<div id="navbar-car-dot" style={{ "margin-left": "8px", "width": "8px", "height": "8px", "border-radius": "4px", "background-color": "tomato" }}></div>)
                : (<div id="navbar-car-dot"></div>)
            }
          </li>
          <li>
            <Link to="/subscription">
              Συνδρομή
            </Link>
            {!isSubscribed || isSubscriptionPaused || !isSubscriptionPaid || toBeCancelled
              ? (<div id="navbar-car-dot" style={{ "margin-left": "8px", "width": "8px", "height": "8px", "border-radius": "4px", "background-color": "orange" }}></div>)
              : (<div id="navbar-car-dot"></div>)
            }
          </li>
        </ul>
        <div onClick={() => { document.querySelector(".sign-out-diag").classList.toggle("expanded"); document.body.classList.toggle('freeze') }} class="logout">
          {(() => {
            if (userHasPhoto) {
              return (
                <>
                  <img class="google-img" src={userPhoto} alt="" />
                </>
              );
            } else {
              return (
                <>
                  <div class="user_image_wrapper">
                    <img class="user_image" src={img_user} alt="" />
                  </div>
                </>
              );
            }
          })()}
          {/* <div class="user_image_wrapper">
            <img class="user_image" src={img_user} alt="" />
          </div> */}
        </div>
      </div>
      <div class="nav-mobile">
        <ul className="navbar-nav">
          <li>
            <Link to="/" onClick={() => { document.querySelector(".nav-mobile").classList.toggle("expanded") }}>
              Αρχική
            </Link>
          </li>
          <li>
            <Link to="/car" onClick={() => { document.querySelector(".nav-mobile").classList.toggle("expanded") }}>
              Car
            </Link>
            {!userFirestoreData?.cargrConnectionStatus || userFirestoreData?.cargrConnectionStatus === "err-cred"
              ? (<div id="navbar-car-dot" style={{ "margin-left": "8px", "width": "8px", "height": "8px", "border-radius": "4px", "background-color": "orange" }}></div>)
              : userFirestoreData?.cargrConnectionStatus === "err-other"
                ? (<div id="navbar-car-dot" style={{ "margin-left": "8px", "width": "8px", "height": "8px", "border-radius": "4px", "background-color": "tomato" }}></div>)
                : (<div id="navbar-car-dot"></div>)
            }
          </li>
          <li>
            <Link to="/subscription" onClick={() => { document.querySelector(".nav-mobile").classList.toggle("expanded") }}>
              Συνδρομή
            </Link>
            {!isSubscribed || isSubscriptionPaused || !isSubscriptionPaid || toBeCancelled
              ? (<div id="navbar-car-dot" style={{ "margin-left": "8px", "width": "8px", "height": "8px", "border-radius": "4px", "background-color": "orange" }}></div>)
              : (<div id="navbar-car-dot"></div>)
            }
          </li>
        </ul>
      </div>
    </nav>
  );
}

function RoutesList(props) {
  return (
    <>
      <main>
        <Routes>
          <Route path="/" element={<Home data={props.data} />}></Route>
          <Route path="/car" element={<Cargr data={props.data} />}></Route>
          <Route path="/car-connect" element={<CarConnect data={props.data} />}></Route>
          <Route path="/subscription" element={<Subscription data={props.data} />}></Route>
          <Route path="/update" element={<Update data={props.data} />}></Route>
          <Route path="/logout" element={<Logout_debug />}></Route>
        </Routes>
      </main>
    </>
  )
}
