export default function Home(props) {
    document.querySelector("#nav-bar")?.classList.remove("hidden");
    while (document.body.classList.length > 0) { document.body.classList.remove(document.body.classList[0]) };
    document.body.classList.add('home-page');

    const { userUID, userFirestoreData, loadingScreenData_Main } = props.data;
    const { dataReady_userFirestoreData, dataReady_subscriptions } = loadingScreenData_Main;
    const { img_clock, img_reload, img_check } = props.data.img;

    function isISODate(str) {
        // Define the regex pattern for ISO date format
        const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?Z$/;
        // Test the string against the regex pattern
        return isoDatePattern.test(str);
    }

    // function getIsoDateNowInGreece() {
    //     // Create a new date object.
    //     const date = new Date();
    //     const hours = date.getUTCHours() + getTimezoneOffset("Europe/Athens");
    //     date.setUTCHours(hours);
    //     date.setUTCSeconds(0);
    //     date.setUTCMilliseconds(0);

    //     // Convert the date object to an ISO string and return it.
    //     return date.toISOString();
    // }

    function getIsoDateNowPlus30MinInGreece() {
        // Create a new date object.
        const date = new Date();
    
        const hours = date.getUTCHours() + getTimezoneOffset("Europe/Athens");
        const minutes = date.getUTCMinutes();
        if (minutes >= 0 && minutes < 30) {
            date.setUTCHours(hours);
            date.setUTCMinutes(30);
        } else {
            date.setUTCHours(hours + 1);
            date.setUTCMinutes(0);
        }
    
        date.setUTCSeconds(0);
        date.setUTCMilliseconds(0);
    
        // Convert the date object to an ISO string and return it.
        return date.toISOString();
    }

    function getTimezoneOffset(timeZone, date = new Date()) {
        const tz = date.toLocaleString("en", { timeZone, timeStyle: "long" }).split(" ").slice(-1)[0];
        const dateString = date.toString();
        const offset = Date.parse(`${dateString} UTC`) - Date.parse(`${dateString} ${tz}`);

        return (offset / 1000 / 60 / 60);
    }

    function timeData(isoDate) {
        if (!isISODate(isoDate) && isoDate === "now") {
            const isoDate_nowPlus30Min = getIsoDateNowPlus30MinInGreece();

            const day = isoDate_nowPlus30Min.split("T")[0].split("-")[2];
            const month = isoDate_nowPlus30Min.split("T")[0].split("-")[1];
            const year = isoDate_nowPlus30Min.split("T")[0].split("-")[0];
            const minutes = isoDate_nowPlus30Min.split("T")[1].split(":")[1];
            const hours = isoDate_nowPlus30Min.split("T")[1].split(":")[0];
            const date = `${day}-${month}-${year}`;
            const time = `${hours}:${minutes}`;

            return {
                day,
                month,
                shortMonth: false,
                year,
                minutes,
                hours,
                date,
                shortDate: false,
                time,
                relativeDate: "Σήμερα",
            }
        } else if (!isISODate(isoDate) && isoDate !== "now") {
            return {
                day: false,
                month: false,
                shortMonth: false,
                year: false,
                minutes: false,
                hours: false,
                date: false,
                shortDate: false,
                time: false,
                relativeDate: false
            }
        };

        function getRelativeDate(isoDate, importedDate) {
            const day = isoDate.split("T")[0].split("-")[2];
            const month = isoDate.split("T")[0].split("-")[1];
            const year = isoDate.split("T")[0].split("-")[0];

            const date = new Date(isoDate);
            date.setDate(day);
            date.setMonth(month - 1);
            date.setFullYear(year);

            const today = new Date();
            const yesterday = new Date(today);
            yesterday.setDate(yesterday.getDate() - 1);
            const tomorrow = new Date(today);
            tomorrow.setDate(tomorrow.getDate() + 1);

            if (isSameDate(date, today)) {
                return "Σήμερα";
            } else if (isSameDate(date, yesterday)) {
                return "Εχτές";
            } else if (isSameDate(date, tomorrow)) {
                return "Αύριο";
            } else {
                return importedDate;
            }

            function isSameDate(date1, date2) {
                return (
                    date1.getFullYear() === date2.getFullYear() &&
                    date1.getMonth() === date2.getMonth() &&
                    date1.getDate() === date2.getDate()
                );
            }
        }

        const day = isoDate.split("T")[0].split("-")[2];
        const month = isoDate.split("T")[0].split("-")[1];
        const year = isoDate.split("T")[0].split("-")[0];
        const minutes = isoDate.split("T")[1].split(":")[1];
        const hours = isoDate.split("T")[1].split(":")[0];
        const date = `${day}-${month}-${year}`;
        const time = `${hours}:${minutes}`;

        let shortMonth = "";
        if (Number(month) == 1) { shortMonth = "Ιαν" }
        else if (Number(month) == 2) { shortMonth = "Φεβ" }
        else if (Number(month) == 3) { shortMonth = "Μαρ" }
        else if (Number(month) == 4) { shortMonth = "Απρ" }
        else if (Number(month) == 5) { shortMonth = "Μαϊ" }
        else if (Number(month) == 6) { shortMonth = "Ιουν" }
        else if (Number(month) == 7) { shortMonth = "Ιουλ" }
        else if (Number(month) == 8) { shortMonth = "Αυγ" }
        else if (Number(month) == 9) { shortMonth = "Σεπ" }
        else if (Number(month) == 10) { shortMonth = "Οκτ" }
        else if (Number(month) == 11) { shortMonth = "Νοε" }
        else if (Number(month) == 12) { shortMonth = "Δεκ" }

        let shortDate = `${day} ${shortMonth} ${year}`;
        if (!shortMonth) { shortDate = `${day} ${month} ${year}` };

        const relativeDate = getRelativeDate(isoDate, date);

        return {
            day,
            month,
            shortMonth,
            year,
            minutes,
            hours,
            date,
            shortDate,
            time,
            relativeDate
        }
    };

    function getDayOfWeek(historyData) {
        if (!historyData) {
            return "";
        };

        const isoDate = historyData?.isoDate;
        const dayOfWeek_num = new Date(isoDate).getDay();

        let dayOfWeek = "";
        switch (dayOfWeek_num) {
            case 0: dayOfWeek = "Κυριακή"; break;
            case 1: dayOfWeek = "Δευτέρα"; break;
            case 2: dayOfWeek = "Τρίτη"; break;
            case 3: dayOfWeek = "Τετάρτη"; break;
            case 4: dayOfWeek = "Πέμπτη"; break;
            case 5: dayOfWeek = "Παρασκευή"; break;
            case 6: dayOfWeek = "Σάββατο"; break;
        }

        return dayOfWeek;
    }

    function historyData() {
        const history = userFirestoreData?.history;
        if (!history?.[0]) { return [] };

        const historyReversed = new Array();
        history.forEach((entry) => {
            historyReversed.unshift(entry);
        });
        return historyReversed;
    }

    function getHistoryData(historyData) {
        if (!historyData) {
            return {
                number: "",
                date: "",
                time: "",
                category: "",
                numberOfAdsUpdated: "",
            }
        };

        const number = historyData?.number || "";

        const isoDate = historyData?.isoDate;
        const date = timeData(isoDate).shortDate || "";
        const time = timeData(isoDate).time || "";
        const relativeDate = timeData(isoDate).relativeDate || "";

        const numberOfAdsUpdated = historyData?.numberOfAdsUpdated;

        const categoryId = historyData?.category || "";
        let category = "";
        if (categoryId === "antallaktika_aksesoyar") { category = "Ανταλλακτικα & Αξεσουάρ" }
        else if (categoryId === "xyma") { category = "Μικρές αγγελίες" }
        else if (categoryId === "aytokinita") { category = "Αυτοκίνητα" }
        else if (categoryId === "motosykletes") { category = "Μοτοσυκλέτες" }
        else if (categoryId === "skafi") { category = "Σκάφη" }
        else if (categoryId === "podilata") { category = "Ποδήλατα" }
        else if (categoryId === "fortigaEos7_5t") { category = "Φορτηγά έως 7.5τ" }
        else if (categoryId === "fortigaAnoTon7_5t") { category = "Φορτηγά άνω των 7.5τ" }
        else if (categoryId === "leoforeia") { category = "Λεωφορεία" }
        else if (categoryId === "mixanimata") { category = "Μηχανήματα" }
        else if (categoryId === "traktores") { category = "Τράκτορες" }
        else if (categoryId === "epikathimena") { category = "Επικαθήμενα" }
        else if (categoryId === "georgika") { category = "Γεωργικά" }
        else if (categoryId === "klark") { category = "Κλάρκ" }
        else if (categoryId === "rymoylkes_treiler") { category = "Ρυμούλκες/τρέιλερ" }
        else if (categoryId === "taksi") { category = "Ταξί" }
        else if (categoryId === "troxospita") { category = "Τροχόσπιτα" }
        else if (categoryId === "aytokinoymena") { category = "Αυτοκινούμενα" }
        else if (categoryId === "tilekateythynomena") { category = "Τηλεκατευθυνόμενα" }
        else if (categoryId === "goKart") { category = "Go kart" }
        else if (categoryId === "thalassiaSpor") { category = "Θαλάσσια σπόρ" }
        else if (categoryId === "xeimerinaSpor") { category = "Χειμερινά σπόρ" }
        else if (categoryId === "aerathlima") { category = "Αεράθλημα" }
        else if (categoryId === "aggeliesErgasias") { category = "Αγγελίες Εργασίας" }
        else if (categoryId === "enoikiaseis") { category = "Ενοικιάσεις" }

        return {
            number,
            date,
            time,
            relativeDate,
            isoDate,
            category,
            numberOfAdsUpdated
        }
    }

    const hd = historyData();

    if (!dataReady_userFirestoreData || !dataReady_subscriptions) { return (<></>) }

    if (!userFirestoreData?.cargrConnectionStatus && !userFirestoreData?.history?.[0]) {
        return (
            <>
                <Demo data={props.data} />
            </>
        );
    } else {
        return (
            <>
                <div class="data-panel1 data-panel-home panel">
                    <div class="panel-heading">Σύνοψη</div>
                    <div class="panel-content">
                        <div class="info-box">
                            <div class="info-box-img" style={{ "background-color": "#ce383a" }}>
                                <img src={img_clock} alt="" />
                            </div>
                            <div class="info-box-right">
                                <div class="info-box-heading">Τελευταία ανανέωση</div>
                                <div class="info-box-content">{timeData(getHistoryData(historyData()?.[0]).isoDate).relativeDate || "Δεν υπάρχουν δεδομένα"}</div>
                                <div class="info-box-bottom">{timeData(getHistoryData(historyData()?.[0]).isoDate).time || "Δεν υπάρχουν δεδομένα"}</div>
                            </div>
                        </div>
                        <div class="info-box">
                            <div class="info-box-img" style={{ "background-color": "#dd5704" }}>
                                <img src={img_clock} alt="" />
                            </div>
                            <div class="info-box-right">
                                <div class="info-box-heading">Επόμενη ανανέωση</div>
                                <div class="info-box-content">{timeData(userFirestoreData?.nextScheduledUpdate).relativeDate || "Δεν υπάρχουν δεδομένα"}</div>
                                <div class="info-box-bottom">{(timeData(userFirestoreData?.nextScheduledUpdate).time && `${userFirestoreData?.updatePrerences?.nextScheduledUpdate_hourUserChoice < 10 && `0${userFirestoreData?.updatePrerences?.nextScheduledUpdate_hourUserChoice}` || userFirestoreData?.updatePrerences?.nextScheduledUpdate_hourUserChoice}:${timeData(userFirestoreData?.nextScheduledUpdate).time.split(":")[1]}`) || "Δεν υπάρχουν δεδομένα"}</div>
                            </div>
                        </div>
                        <div class="info-box">
                            <div class="info-box-img" style={{ "background-color": "#82b250" }}>
                                <img src={img_reload} alt="" />
                            </div>
                            <div class="info-box-right">
                                <div class="info-box-heading">Συνολικές ανανεώσεις</div>
                                <div class="info-box-content">{(getHistoryData(historyData()?.[0]).number && getHistoryData(historyData()?.[0]).number * 200) || "0"}</div>
                                <div class="info-box-bottom">{(getHistoryData(historyData()?.[0]).number && Math.min(...[getHistoryData(historyData()?.[0]).number * 200, 1400])) || "Δεν έχουν γίνει ακόμα"} ανανεώσεις αυτή την εβδομάδα</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="data-panel2 data-panel-home panel">
                    <div class="panel-heading">Ιστορικό Ανανεώσεων<br /><span style={{ "font-size": "14px", "padding-top": "8px", "color": "#606060" }}>Τελευταίες 7 μέρες</span></div>
                    <div class="panel-content">
                        <table>
                            <thead>
                                <tr>
                                    <th>Αρ. Ανανέωσης</th>
                                    <th>Ημ/νία & Ώρα</th>
                                    <th>Κατηγορία</th>
                                    <th>Ανανεώθηκαν</th>
                                </tr>
                            </thead>
                            <tbody>
                                <div class="line"></div>
                                <tr class="flat-b-r-top" >
                                    <TableData number={"0"} data={{ getHistoryData, historyData, getDayOfWeek, img_check }} />
                                </tr>
                                <div class="line"></div>
                                <tr>
                                    <TableData number={"1"} data={{ getHistoryData, historyData, getDayOfWeek, img_check }} />
                                </tr>
                                <div class="line"></div>
                                <tr>
                                    <TableData number={"2"} data={{ getHistoryData, historyData, getDayOfWeek, img_check }} />
                                </tr>
                                <div class="line"></div>
                                <tr>
                                    <TableData number={"3"} data={{ getHistoryData, historyData, getDayOfWeek, img_check }} />
                                </tr>
                                <div class="line"></div>
                                <tr>
                                    <TableData number={"4"} data={{ getHistoryData, historyData, getDayOfWeek, img_check }} />
                                </tr>
                                <div class="line"></div>
                                <tr>
                                    <TableData number={"5"} data={{ getHistoryData, historyData, getDayOfWeek, img_check }} />
                                </tr>
                                <div class="line"></div>
                                <tr>
                                    <TableData number={"6"} data={{ getHistoryData, historyData, getDayOfWeek, img_check }} />
                                </tr>
                                <div class="line"></div>
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
}

function TableData(props) {
    const { getHistoryData, historyData, getDayOfWeek, img_check } = props.data;
    const number = props.number;
    const hasData = !!getHistoryData(historyData()?.[number]).number;

    if (hasData) {
        return (
            <>
                <td class="no active">{(getHistoryData(historyData()?.[number]).number && getHistoryData(historyData()?.[number]).number) || "Δεν υπάρχουν ακόμα δεδομένα"}</td>
                <td class="date_time">
                    <div class="date_time-inner">
                        <span class="day-of-week">{(getHistoryData(historyData()?.[number]).date && getDayOfWeek(historyData()?.[number])) || ""}</span>
                        <span class="date">{(getHistoryData(historyData()?.[number]).date && getHistoryData(historyData()?.[number]).date) || ""}</span>
                        <span class="time">{getHistoryData(historyData()?.[number]).time || ""}</span>
                    </div>
                </td>
                <td class="category">
                    <div class="number-updated-inner">
                        <img class="check" src={img_check} alt="" />
                        <span class="number">{getHistoryData(historyData()?.[number]).numberOfAdsUpdated || ""}</span>
                    </div>
                    <div class="category-inner">
                        <span>{getHistoryData(historyData()?.[number]).category || ""}</span>
                    </div>
                </td>
                <td class="number-updated">
                    <div class="number-updated-inner">
                        <img class="check" src={img_check} style={(getHistoryData(historyData()?.[number]).numberOfAdsUpdated && { "display": "initial" }) || { "display": "none" }} alt="" />
                        <span class="number">{getHistoryData(historyData()?.[number]).numberOfAdsUpdated || ""}</span>
                    </div>
                </td>
            </>
        );
    } else {
        return (
            <>
                <td class="no">Δεν υπάρχουν ακόμα δεδομένα</td>
                <td class="date_time">
                    <div class="date_time-inner">
                        <span class="day-of-week" style={{ "display": "none" }}></span>
                        <span class="date" style={{ "display": "none" }}></span>
                        <span class="time">-</span>
                    </div>
                </td>
                <td class="category" style={{ "background-color": "rgba(0,0,0,0)" }}>
                    <div class="category-inner">
                        <span>-</span>
                    </div>
                </td>
                <td class="number-updated">
                    <div class="number-updated-inner">
                        <span class="number">-</span>
                    </div>
                </td>
            </>
        );
    }
}

function Demo(props) {
    const { img_clock, img_reload, img_check } = props.data.img;
    function getShortDate(isoDate) {
        const day = isoDate.split("T")[0].split("-")[2];
        const month = isoDate.split("T")[0].split("-")[1];
        const year = isoDate.split("T")[0].split("-")[0];

        let shortMonth = "";
        if (Number(month) == 1) { shortMonth = "Ιαν" }
        else if (Number(month) == 2) { shortMonth = "Φεβ" }
        else if (Number(month) == 3) { shortMonth = "Μαρ" }
        else if (Number(month) == 4) { shortMonth = "Απρ" }
        else if (Number(month) == 5) { shortMonth = "Μαϊ" }
        else if (Number(month) == 6) { shortMonth = "Ιουν" }
        else if (Number(month) == 7) { shortMonth = "Ιουλ" }
        else if (Number(month) == 8) { shortMonth = "Αυγ" }
        else if (Number(month) == 9) { shortMonth = "Σεπ" }
        else if (Number(month) == 10) { shortMonth = "Οκτ" }
        else if (Number(month) == 11) { shortMonth = "Νοε" }
        else if (Number(month) == 12) { shortMonth = "Δεκ" }

        let shortDate = `${day} ${shortMonth} ${year}`;
        if (!shortMonth) { shortDate = `${day} ${month} ${year}` };

        return shortDate
    };

    function getDayOfWeek(isoDate) {
        const dayOfWeek_num = new Date(isoDate).getDay();

        let dayOfWeek = "";
        switch (dayOfWeek_num) {
            case 0: dayOfWeek = "Κυριακή"; break;
            case 1: dayOfWeek = "Δευτέρα"; break;
            case 2: dayOfWeek = "Τρίτη"; break;
            case 3: dayOfWeek = "Τετάρτη"; break;
            case 4: dayOfWeek = "Πέμπτη"; break;
            case 5: dayOfWeek = "Παρασκευή"; break;
            case 6: dayOfWeek = "Σάββατο"; break;
        }

        return dayOfWeek;
    }

    function get7IsoDates() {
        const isoDates = new Array();
        for (let i = 0; i < 7; i++) {
            const date = new Date();
            date.setUTCDate(date.getUTCDate() - i);
            isoDates.push(date.toISOString());
        };
        return isoDates;
    }

    return (
        <>
            <div class="demo-banner">Τα δεδομένα που εμφανίζονται εδώ είναι δειγματικά. Πραγματικά δεδομένα θα εμφανιστούν με την σύνδεση στο car</div>
            <div class="data-panel1 data-panel-home panel">
                <div class="panel-heading">Σύνοψη</div>
                <div class="panel-content">
                    <div class="info-box">
                        <div class="info-box-img" style={{ "background-color": "#ce383a" }}>
                            <img src={img_clock} alt="" />
                        </div>
                        <div class="info-box-right">
                            <div class="info-box-heading">Τελευταία ανανέωση</div>
                            <div class="info-box-content">Σήμερα</div>
                            <div class="info-box-bottom">7:10</div>
                        </div>
                    </div>
                    <div class="info-box">
                        <div class="info-box-img" style={{ "background-color": "#dd5704" }}>
                            <img src={img_clock} alt="" />
                        </div>
                        <div class="info-box-right">
                            <div class="info-box-heading">Επόμενη ανανέωση</div>
                            <div class="info-box-content">Αύριο</div>
                            <div class="info-box-bottom">8:25</div>
                        </div>
                    </div>
                    <div class="info-box">
                        <div class="info-box-img" style={{ "background-color": "#82b250" }}>
                            <img src={img_reload} alt="" />
                        </div>
                        <div class="info-box-right">
                            <div class="info-box-heading">Συνολικές ανανεώσεις</div>
                            <div class="info-box-content">24.000</div>
                            <div class="info-box-bottom">1.400 ανανεώσεις αυτή την εβδομάδα</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="data-panel2 data-panel-home panel">
                <div class="panel-heading">Ιστορικό ανανεώσεων<br /><span style={{ "font-size": "14px", "padding-top": "8px", "color": "#606060" }}>Τελευταίες 7 μέρες</span></div>
                <div class="panel-content">
                    <table>
                        <thead>
                            <tr>
                                <th>Αρ. Ανανέωσης</th>
                                <th>Ημ/νία & Ώρα</th>
                                <th>Κατηγορία</th>
                                <th>Ανανεώθηκαν</th>
                            </tr>
                        </thead>
                        <tbody>
                            <div class="line"></div>
                            <tr class="flat-b-r-top">
                                <td class="no active">120</td>
                                <td class="date_time">
                                    <div class="date_time-inner">
                                        <span class="day-of-week">{getDayOfWeek(get7IsoDates()[0])}</span>
                                        <span class="date">{getShortDate(get7IsoDates()[0])}</span>
                                        <span class="time">7:10</span>
                                    </div>
                                </td>
                                <td class="category">
                                    <div class="number-updated-inner">
                                        <img class="check" src={img_check} alt="" />
                                        <span class="number">200</span>
                                    </div>
                                    <div class="category-inner">
                                        <span>
                                            Ανταλλακτικα & Αξεσουάρ
                                        </span>
                                    </div>
                                </td>
                                <td class="number-updated">
                                    <div class="number-updated-inner">
                                        <img class="check" src={img_check} alt="" />
                                        <span class="number">200</span>
                                    </div>
                                </td>
                            </tr>
                            <div class="line"></div>
                            <tr>
                                <td class="no active">119</td>
                                <td class="date_time">
                                    <div class="date_time-inner">
                                        <span class="day-of-week">{getDayOfWeek(get7IsoDates()[1])}</span>
                                        <span class="date">{getShortDate(get7IsoDates()[1])}</span>
                                        <span class="time">7:36</span>
                                    </div>
                                </td>
                                <td class="category">
                                    <div class="number-updated-inner">
                                        <img class="check" src={img_check} alt="" />
                                        <span class="number">200</span>
                                    </div>
                                    <div class="category-inner">
                                        <span>
                                            Ανταλλακτικα & Αξεσουάρ
                                        </span>
                                    </div>
                                </td>
                                <td class="number-updated">
                                    <div class="number-updated-inner">
                                        <img class="check" src={img_check} alt="" />
                                        <span class="number">200</span>
                                    </div>
                                </td>
                            </tr>
                            <div class="line"></div>
                            <tr>
                                <td class="no active">118</td>
                                <td class="date_time">
                                    <div class="date_time-inner">
                                        <span class="day-of-week">{getDayOfWeek(get7IsoDates()[2])}</span>
                                        <span class="date">{getShortDate(get7IsoDates()[2])}</span>
                                        <span class="time">8:42</span>
                                    </div>
                                </td>
                                <td class="category">
                                    <div class="number-updated-inner">
                                        <img class="check" src={img_check} alt="" />
                                        <span class="number">200</span>
                                    </div>
                                    <div class="category-inner">
                                        <span>
                                            Ανταλλακτικα & Αξεσουάρ
                                        </span>
                                    </div>
                                </td>
                                <td class="number-updated">
                                    <div class="number-updated-inner">
                                        <img class="check" src={img_check} alt="" />
                                        <span class="number">200</span>
                                    </div>
                                </td>
                            </tr>
                            <div class="line"></div>
                            <tr>
                                <td class="no active">117</td>
                                <td class="date_time">
                                    <div class="date_time-inner">
                                        <span class="day-of-week">{getDayOfWeek(get7IsoDates()[3])}</span>
                                        <span class="date">{getShortDate(get7IsoDates()[3])}</span>
                                        <span class="time">9:03</span>
                                    </div>
                                </td>
                                <td class="category">
                                    <div class="number-updated-inner">
                                        <img class="check" src={img_check} alt="" />
                                        <span class="number">200</span>
                                    </div>
                                    <div class="category-inner">
                                        <span>
                                            Μικρές αγγελίες
                                        </span>
                                    </div>
                                </td>
                                <td class="number-updated">
                                    <div class="number-updated-inner">
                                        <img class="check" src={img_check} alt="" />
                                        <span class="number">200</span>
                                    </div>
                                </td>
                            </tr>
                            <div class="line"></div>
                            <tr>
                                <td class="no active">116</td>
                                <td class="date_time">
                                    <div class="date_time-inner">
                                        <span class="day-of-week">{getDayOfWeek(get7IsoDates()[4])}</span>
                                        <span class="date">{getShortDate(get7IsoDates()[4])}</span>
                                        <span class="time">8:18</span>
                                    </div>
                                </td>
                                <td class="category">
                                    <div class="number-updated-inner">
                                        <img class="check" src={img_check} alt="" />
                                        <span class="number">200</span>
                                    </div>
                                    <div class="category-inner">
                                        <span>
                                            Ανταλλακτικα & Αξεσουάρ
                                        </span>
                                    </div>
                                </td>
                                <td class="number-updated">
                                    <div class="number-updated-inner">
                                        <img class="check" src={img_check} alt="" />
                                        <span class="number">200</span>
                                    </div>
                                </td>
                            </tr>
                            <div class="line"></div>
                            <tr>
                                <td class="no active">115</td>
                                <td class="date_time">
                                    <div class="date_time-inner">
                                        <span class="day-of-week">{getDayOfWeek(get7IsoDates()[5])}</span>
                                        <span class="date">{getShortDate(get7IsoDates()[5])}</span>
                                        <span class="time">7:06</span>
                                    </div>
                                </td>
                                <td class="category">
                                    <div class="number-updated-inner">
                                        <img class="check" src={img_check} alt="" />
                                        <span class="number">200</span>
                                    </div>
                                    <div class="category-inner">
                                        <span>
                                            Ανταλλακτικα & Αξεσουάρ
                                        </span>
                                    </div>
                                </td>
                                <td class="number-updated">
                                    <div class="number-updated-inner">
                                        <img class="check" src={img_check} alt="" />
                                        <span class="number">200</span>
                                    </div>
                                </td>
                            </tr>
                            <div class="line"></div>
                            <tr>
                                <td class="no active">114</td>
                                <td class="date_time">
                                    <div class="date_time-inner">
                                        <span class="day-of-week">{getDayOfWeek(get7IsoDates()[6])}</span>
                                        <span class="date">{getShortDate(get7IsoDates()[6])}</span>
                                        <span class="time">7:21</span>
                                    </div>
                                </td>
                                <td class="category">
                                    <div class="number-updated-inner">
                                        <img class="check" src={img_check} alt="" />
                                        <span class="number">200</span>
                                    </div>
                                    <div class="category-inner">
                                        <span>
                                            Μικρές αγγελίες
                                        </span>
                                    </div>
                                </td>
                                <td class="number-updated">
                                    <div class="number-updated-inner">
                                        <img class="check" src={img_check} alt="" />
                                        <span class="number">200</span>
                                    </div>
                                </td>
                            </tr>
                            <div class="line"></div>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}