import { fetchFromAPI } from '../helpers';
import firebase, { auth, db } from '../firebase';
import { useSigninCheck } from 'reactfire';
import { useNavigate } from 'react-router-dom';
import img_login_background from "./img/loginBackground.webp";
// let signInExecuted = false;

function SignIn(props) {
    const { setDataReady_userFirestoreData, setDataReady_subscriptions } = props.loadingScreenData_Main;
    const img_google = props.img_google;
    const navigate = useNavigate();

    const signIn = async () => {
        navigate(`/`);

        const credential = await auth.signInWithPopup(
            new firebase.auth.GoogleAuthProvider()
        );
        const { uid, email } = credential.user;

        setDataReady_userFirestoreData(false);
        setDataReady_subscriptions(false);

        let userData = (await db.collection('users').doc(uid).get()).data();
        if (!userData?.email) {
            console.log("New User")
            await db.collection('users').doc(uid).set({
                email,
                updatePrerences: {
                    nextScheduledUpdate_hourUserChoice: Math.floor(Math.random() * 24),
                    numberOfAdsToUpdate: 200,
                    queryUrlParameters: {
                        mainCategory: "antallaktika_aksesoyar",
                        condition: "all",
                    },
                }
            }, { merge: true });
        }
        await fetchFromAPI('customer', { method: 'GET' })
    };

    return (
        <button className="login-btn" onClick={() => signIn()}>
            <div class="g_logo-container">
                <img class="g_logo" src={img_google} alt="" />
            </div>
            Σύνδεση με Google
        </button>
    );
}

export default function Login(props) {
    const { setDataReady_userFirestoreData, setDataReady_subscriptions } = props.loadingScreenData_Main;
    while (document.body.classList.length > 0) { document.body.classList.remove(document.body.classList[0]) };
    document.body.classList.add('login-page');

    const { status, data: signInCheckResult } = useSigninCheck();
    const statusIsSuccess = status === "success";
    const isSingedIn = signInCheckResult?.signedIn === true;

    if (statusIsSuccess && !isSingedIn) {
        // Set those as true to remove the main loading screen
        setDataReady_userFirestoreData(true);
        setDataReady_subscriptions(true);

        return (
            <>
                    <img id="wrapper-background-image" src={img_login_background} alt="" />
                <div id='login'>
                    <div class="wrapper-front">
                        <div class="header">
                            <h1 class="heading">Καλωσήλθατε</h1>
                            <div class="request-account">
                                <div>
                                    <div>Κάντε κλικ παρακάτω για να συνδεθείτε</div>
                                    <div>στην πλατφόρμα</div>
                                </div>
                            </div>
                        </div>
                        <div class="container">
                            <div class="google-login">
                                <SignIn img_google={props.img_google} loadingScreenData_Main={props.loadingScreenData_Main} />
                            </div>
                            <div class="divider-line">
                                <div class="text">ή συνεχίστε με email/κωδικό</div>
                            </div>
                            <div class="login-field">
                                <input class="login-field__input" type="text" name="username" id="username-input" autoComplete="username" required />
                                <p class="login-field__heading">Email</p>
                            </div>
                            <div class="login-field">
                                <input class="login-field__input" type="password" name="password" id="password-input" autoComplete="current-password" required />
                                <p class="login-field__heading">Κωδικός</p>
                            </div>
                            <div class="login-options">
                                <input type="checkbox" name="remember-user" id="remember-user" />
                                <div>Να παραμείνω συνδεδεμένος/η</div>
                            </div>
                            <a class="forgot-password" href="#">Ξεχάσατε τον κωδικό πρόσβασης;</a>
                            <button class="login-btn" type="submit">Σύνδεση</button>
                        </div>
                    </div>
                </div>
            </>
        );
    } else { return (<></>) }
}