import { getFunctions, httpsCallable } from 'firebase/functions';
import { app, db } from '../firebase';
import { useNavigate } from "react-router-dom";
import { fetchFromAPI } from "../helpers";


export default function CarConnect(props) {
    const { userFirestoreData, userUID, loadingScreenData_Main } = props.data;
    const {dataReady_userFirestoreData, dataReady_subscriptions} = loadingScreenData_Main;
    const {img_loading} = props.data.img;
    const {getSubscriptions} = props.data.fn;
    const navigate = useNavigate();
    document.querySelector("#nav-bar")?.classList.add("hidden");
    while (document.body.classList.length > 0) {document.body.classList.remove(document.body.classList[0])};
    document.body.classList.add('cargr-connect');

    const checkCredentials = async () => {
        const usernameInput = document.querySelector("#username-input");
        const passwordInput = document.querySelector("#password-input");
        const loginButton = document.querySelector(".form__container-login-btn > button");
        const backButton = document.querySelector("#back-button");
        const containerAlert = document.querySelector(".container__alert");
        const status = document.querySelector(".container__alert > .status > p");

        const username = document.getElementById("username-input").value;
        const password = document.getElementById("password-input").value;

        if (!username || !password) {
            containerAlert.className = "container__alert container__alert-active err";
            status.textContent = "Παρακαλώ συμπληρώστε όλα τα πεδία";
        } else {
            containerAlert.className = "container__alert container__alert-active loading";
            status.textContent = "Γίνεται σύνδεση";

            usernameInput.setAttribute('disabled', '');
            passwordInput.setAttribute('disabled', '');
            loginButton.className = "inactive-7";
            loginButton.setAttribute('disabled', '');
            backButton.classList?.add("hidden");

            // TODO: Add restart if it is taking too long
            const loginStatus = await (async () => {
                const functions = getFunctions(app, "europe-west1");
                const checkCredentials_ = httpsCallable(functions, "checkCredentials");

                try {
                    console.log(`Logging in. Please wait`);
                    const result = await checkCredentials_({ user: userUID, username, password });
                    console.log('Result:', result);
                    return result;
                } catch (error) {
                    console.error('Error:', error);
                    return { data: "err-other" }
                }
            })();

            if (loginStatus.data === "ok" || loginStatus.data === "ok-exp") {
                await db.collection('users').doc(userUID).set({
                    cargrConnectionStatus: loginStatus.data,
                    cargrConnectionRetries: 0,
                    cargrCredentials: {
                        username,
                        password
                    }
                }, { merge: true });

                const subscriptionId = userFirestoreData?.activeSubscription?.id;
                if (subscriptionId) {
                    fetchFromAPI('subscriptions/resume/' + subscriptionId, { method: 'PATCH' });
                }
            }

            usernameInput.removeAttribute('disabled');
            passwordInput.removeAttribute('disabled');
            loginButton.className = "";
            loginButton.removeAttribute('disabled');
            backButton.classList?.remove("hidden");

            switch (loginStatus.data) {
                case "err-cred":
                    containerAlert.className = "container__alert container__alert-active err";
                    status.textContent = "Άγνωστος χρήστης ή λάθος κωδικός";
                    break;
                case "err-other":
                    containerAlert.className = "container__alert container__alert-active err";
                    status.textContent = "Η σύνδεση απέτυχε. Παρακαλώ ξαναπροσπαθήστε αργότερα";
                    break;
                case "ok":
                    containerAlert.className = "container__alert container__alert-active ok";
                    status.textContent = "Σύνδεση επιτυχής!";
                    break;
                case "ok-exp":
                    containerAlert.className = "container__alert container__alert-active ok";
                    status.textContent = "Σύνδεση επιτυχής!";
                    break;
                default:
                    containerAlert.className = "container__alert container__alert-active err";
                    status.textContent = "Η σύνδεση απέτυχε. Παρακαλώ ξαναπροσπαθήστε αργότερα";
            }

            if (loginStatus.data === "ok" || loginStatus.data === "ok-exp") {
                setTimeout(() => {
                    navigate(`/car`);
                    getSubscriptions();
                }, 1000);
            }
        }
    };

    if (!dataReady_userFirestoreData || !dataReady_subscriptions) { return (<></>) }

    return (
        <>
            <div class="login-cargr">
                <div class="container">
                    <div class="container__head">
                        <h2>Σύνδεση για εμπόρους</h2>
                    </div>
                    <div class="container__alert">
                        <div class="status">
                            <p></p>
                            <img src={img_loading} alt="loading" style={{"height": "20px"}} />
                        </div>
                    </div>
                    <div class="container__form">
                        <div class="form__username form__row">
                            <h3 class="form-username__heading form-row__heading">Όνομα χρήστη</h3>
                            <div class="username__input">
                                <input type="text" name="username" id="username-input" autoComplete="username" />
                            </div>
                        </div>
                        <div class="form__password form__row">
                            <h3 class="form-password__heading form-row__heading">Κωδικός</h3>
                            <div class="password__input">
                                <input type="password" name="password" id="password-input" autoComplete="current-password" />
                            </div>
                        </div>
                        <div class="form__container-login-btn">
                            <button type="submit" onClick={() => checkCredentials()}>Σύνδεση</button>
                        </div>
                    </div>
                </div>
                <div class="bottom-label">
                    <h1>Σύνδεση με το car.gr</h1>
                    <h2 id="back-button" onClick={() => navigate(`/car`)}>Πίσω</h2>
                </div>
            </div>
            <div class="fill"></div>
        </>
    )
}
