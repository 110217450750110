import React from 'react';
import { fetchFromAPI } from '../helpers';
import { useNavigate } from "react-router-dom";
import { db } from '../firebase';

export default function Subscription(props) {
    const {subscriptionInfo, loadingScreenData_Main} = props.data;
    const {isSubscribed} = subscriptionInfo;
    const {dataReady_userFirestoreData, dataReady_subscriptions} = loadingScreenData_Main;

    // CSS
    document.querySelector("#nav-bar")?.classList.remove("hidden");
    while (document.body.classList.length > 0) {document.body.classList.remove(document.body.classList[0])};
    document.body.classList.add('subscription-page');

    if (!dataReady_userFirestoreData || !dataReady_subscriptions) { return (<></>) }
    if (!isSubscribed) { return (<><Subscribe data={props.data} /></>) } 
    else { return (<><Subscribed data={props.data} /></>) }
}

function Subscribe(props) {
    const {img_check, img_morning, img_evening, img_arrow} = props.data.img;
    const {stripe, stripeCustomerId} = props.data;
    return (
        <>
            {/* BELOW IS FOR TESTING 1-DAY SUBSCRIPTION */}
            {/* <div style={{"width": "50px", "height": "50px", "background-color": "red"}} onClick={(() => {
                const isSubscribed = false;
                const priceValue = "price_1NEfIqLhHA6SPddpp4zZ3QZZ";
                redirectToCheckout(priceValue, isSubscribed, stripe, stripeCustomerId);
            })()}></div> */}
            <div class="settings-container-outer">
                <div class="settings-container-inner">
                    <div class="data-panel-settings settings-cargr">
                        <div class="settings-head">
                            <div class="settings-heading">Συνδρομή</div>
                            <div class="settings-subheading">Εγγραφείτε παρακάτω</div>
                        </div>
                        <div class="settings-group" style={{"padding-top": "0", "border-top": "0"}}>
                            <div class="plans">
                                <div class="plan-panel">
                                    <div class="title">
                                        <img class="check" src={img_evening} alt="" />
                                        <div class="title-heading_description">
                                            <div class="title-heading">Συνδρομή ανανεώσεων</div>
                                            <div class="title-description">Οι ανανεώσεις πραγματοποιούνται κάθε μέρα, εσείς επιλέγετε το πότε</div>
                                        </div>
                                    </div>
                                    <div class="features br">
                                        <div class="feature">
                                            <div class="check"><img src={img_check} alt="" /></div>
                                            <div>Αυτόματες ανανεώσεις κάθε ημέρα</div>
                                        </div>
                                        <div class="feature">
                                            <div class="check"><img src={img_check} alt="" /></div>
                                            <div>Ώρα ανανέωσης επιλέγετε εσείς</div>
                                        </div>
                                        <div class="feature">
                                            <div class="check"><img src={img_check} alt="" /></div>
                                            <div>Αύξηση πωλήσεων κάθε μήνα</div>
                                        </div>
                                    </div>
                                    <form class="subscription-form">
                                        <input type="radio" id="evening-month-sub" name="choice-evening" value="price_1NP5qvLhHA6SPddpb31GHIVb" defaultChecked={true}/>
                                        <label class="br" for="evening-month-sub">
                                            <div class="title_check">
                                                <div class="input-check">
                                                    <img class="check" src={img_check} alt="" />
                                                </div>
                                                <div class="input-title">Μήνας</div>
                                            </div>
                                            <div class="input-description">Εγγραφή γιά ένα μήνα. <br/>Ακυρώστε οποιαδήποτε στιγμή.</div>
                                            <div class="price_period">
                                                <div class="input-price">15</div>
                                                <div class="input-period">/μήνα</div>
                                            </div>
                                        </label>
                                        <input type="radio" id="evening-year-sub" name="choice-evening" value="price_1NP5qvLhHA6SPddpodmEUCWk" defaultChecked={false}/>
                                        <label for="evening-year-sub">
                                            <div class="title_check">
                                                <div class="input-check">
                                                    <img class="check" src={img_check} alt="" />
                                                </div>
                                                <div class="input-title">Έτος</div>
                                            </div>
                                            <div class="input-description">Εγγραφή γιά ένα έτος.</div>
                                            <div class="price_period">
                                                <div class="input-price">150</div>
                                                <div class="input-period">/έτος</div>
                                            </div>
                                            <div class="input-label">Έκπτωση -20%</div>
                                        </label>
                                    </form>
                                    <div class="action-btn" onClick={() => subscriptionAction({value: "evening", action: "subscribe", props})}>Εγγραφή<img src={img_arrow} alt="" /></div>
                                </div>
                                {/* <div class="plan-panel">
                                    <div class="title">
                                        <img class="check" src={img_morning} alt="" />
                                        <div class="title-heading_description">
                                            <div class="title-heading">Πρωινό Πακέτο</div>
                                            <div class="title-description">Οι ανανεώσεις πραγματοποιούνται κάθε πρωί από τις 7:00 μέχρι τις 9:00</div>
                                        </div>
                                    </div>
                                    <div class="features">
                                        <div class="feature">
                                            <div class="check"><img src={img_check} alt="" /></div>
                                            <div>200 ανανεώσεις την ημέρα</div>
                                        </div>
                                        <div class="feature">
                                            <div class="check"><img src={img_check} alt="" /></div>
                                            <div>Ώρα ανανέωσης 7:00 - 9:00</div>
                                        </div>
                                        <div class="feature">
                                            <div class="check"><img src={img_check} alt="" /></div>
                                            <div>Τουλάχιστον +10% πωλήσεις τον μήνα</div>
                                        </div>
                                        <div class="feature">
                                            <div class="check"><img src={img_check} alt="" /></div>
                                            <div>Ανταγωνιστικό πλεονέκτημα λόγο πρωινών ανανεώσεων</div>
                                        </div>
                                    </div>
                                    <form class="subscription-form">
                                        <input type="radio" id="morning-month-sub" name="choice-morning" value="price_1NP5pdLhHA6SPddpV3HHsaiz" defaultChecked={true}/>
                                        <label class="br" for="morning-month-sub">
                                            <div class="title_check">
                                                <div class="input-check">
                                                    <img class="check" src={img_check} alt="" />
                                                </div>
                                                <div class="input-title">Μήνας</div>
                                            </div>
                                            <div class="input-description">Εγγραφή γιά ένα μήνα. <br/>Ακυρώστε οποιαδήποτε στιγμή.</div>
                                            <div class="price_period">
                                                <div class="input-price">25</div>
                                                <div class="input-period">/μήνα</div>
                                            </div>
                                        </label>
                                        <input type="radio" id="morning-year-sub" name="choice-morning" value="price_1NP5pdLhHA6SPddp7l2diw1l" defaultChecked={false}/>
                                        <label for="morning-year-sub">
                                            <div class="title_check">
                                                <div class="input-check">
                                                    <img class="check" src={img_check} alt="" />
                                                </div>
                                                <div class="input-title">Έτος</div>
                                            </div>
                                            <div class="input-description">Εγγραφή γιά ένα έτος.</div>
                                            <div class="price_period">
                                                <div class="input-price">250</div>
                                                <div class="input-period">/έτος</div>
                                            </div>
                                            <div class="input-label">Έκπτωση -20%</div>
                                        </label>
                                    </form>
                                    <div class="action-btn" onClick={() => subscriptionAction({value: "morning", action: "subscribe", props})}>Εγγραφή<img src={img_arrow} alt="" /></div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export function Update(props) {
    // CSS
    document.querySelector("#nav-bar")?.classList.remove("hidden");
    while (document.body.classList.length > 0) {document.body.classList.remove(document.body.classList[0])};
    document.body.classList.add('subscription-page');
    
    const navigate = useNavigate();
    const {img_check, img_morning, img_evening, img_arrow, img_arrow_black} = props.data.img;
    const activeSubscriptionNameId = props.data?.subscriptionInfo?.active_subscription_object?.nameId;
    const {dataReady_userFirestoreData, dataReady_subscriptions} = props.data.loadingScreenData_Main;

    if (!dataReady_userFirestoreData || !dataReady_subscriptions) { return (<></>) } else {
        return (
            <>
                <div class="settings-container-outer">
                    <div class="settings-container-inner">
                        <div class="data-panel-settings settings-cargr">
                            <div class="settings-head">
                                <div class="back-arrow" onClick={() => navigate(`/subscription`)}><img src={img_arrow_black} alt="" /></div>
                                <div class="settings-heading">Αλλαγή Συνδρομής</div>
                                <div class="settings-subheading">Επιλέξτε ένα πακέτο για να αλλάξτε την συνδρομή σας παρακάτω</div>
                            </div>
                            <div class="settings-group" style={{"padding-top": "0", "border-top": "0"}}>
                                <div class="plans">
                                    <div class="plan-panel">
                                        <div class="title">
                                            <img class="check" src={img_evening} alt="" />
                                            <div class="title-heading_description">
                                                <div class="title-heading">Απογευματινό Πακέτο</div>
                                                <div class="title-description">Οι ανανεώσεις πραγματοποιούνται κάθε μέρα, εσείς επιλέγετε το πότε</div>
                                            </div>
                                        </div>
                                        <div class="features br">
                                            <div class="feature">
                                                <div class="check"><img src={img_check} alt="" /></div>
                                                <div>200 ανανεώσεις την ημέρα</div>
                                            </div>
                                            <div class="feature">
                                                <div class="check"><img src={img_check} alt="" /></div>
                                                <div>Ώρα ανανέωσης επιλέγετε εσείς</div>
                                            </div>
                                            <div class="feature">
                                                <div class="check"><img src={img_check} alt="" /></div>
                                                <div>Τουλάχιστον +10% πωλήσεις τον μήνα</div>
                                            </div>
                                        </div>
                                        <form class="subscription-form">
                                            <input type="radio" id="evening-month-sub" name="choice-evening" value="price_1NP5qvLhHA6SPddpb31GHIVb" disabled={(() => {if (activeSubscriptionNameId === "evening-month-sub") return true})()} defaultChecked={(() => {
                                                switch (activeSubscriptionNameId) {
                                                    case "evening-month-sub": return false;
                                                    case "evening-year-sub": return false;
                                                    case "morning-month-sub": return true;
                                                    case "morning-year-sub": return true;
                                                }
                                            })()} />
                                            <label id="evening-month-sub-label" class={(() => {if (activeSubscriptionNameId === "evening-month-sub") {return "br subscribed"} else {return "br"}})()} for="evening-month-sub">
                                                <div class="title_check">
                                                    <div class="input-check">
                                                        <img class="check" src={img_check} alt="" />
                                                    </div>
                                                    <div class="input-title">Μήνας</div>
                                                </div>
                                                <div class="input-description">Εγγραφή γιά ένα μήνα. <br/>Ακυρώστε οποιαδήποτε στιγμή.</div>
                                                <div class="price_period">
                                                    <div class="input-price">15</div>
                                                    <div class="input-period">/μήνα</div>
                                                </div>
                                            </label>
                                            <input type="radio" id="evening-year-sub" name="choice-evening" value="price_1NP5qvLhHA6SPddpodmEUCWk" disabled={(() => {if (activeSubscriptionNameId === "evening-year-sub") return true})()} defaultChecked={(() => {
                                                switch (activeSubscriptionNameId) {
                                                    case "evening-month-sub": return false;
                                                    case "evening-year-sub": return false;
                                                    case "morning-month-sub": return false;
                                                    case "morning-year-sub": return false;
                                                }
                                            })()} />
                                            <label id="evening-year-sub-label" class={(() => {if (activeSubscriptionNameId === "evening-year-sub") {return "subscribed"}})()} for="evening-year-sub">
                                                <div class="title_check">
                                                    <div class="input-check">
                                                        <img class="check" src={img_check} alt="" />
                                                    </div>
                                                    <div class="input-title">Έτος</div>
                                                </div>
                                                <div class="input-description">Εγγραφή γιά ένα έτος.</div>
                                                <div class="price_period">
                                                    <div class="input-price">150</div>
                                                    <div class="input-period">/έτος</div>
                                                </div>
                                                <div class="input-label">Έκπτωση -20%</div>
                                            </label>
                                        </form>
                                        <div class="action-btn" onClick={() => subscriptionAction({value: "evening", action: "update", props})}>Αλλαγή<img src={img_arrow} alt="" /></div>
                                    </div>
                                    <div class="plan-panel">
                                        <div class="title">
                                            <img class="check" src={img_morning} alt="" />
                                            <div class="title-heading_description">
                                                <div class="title-heading">Πρωινό Πακέτο</div>
                                                <div class="title-description">Οι ανανεώσεις πραγματοποιούνται κάθε πρωί από τις 7:00 μέχρι τις 9:00</div>
                                            </div>
                                        </div>
                                        <div class="features">
                                            <div class="feature">
                                                <div class="check"><img src={img_check} alt="" /></div>
                                                <div>200 ανανεώσεις την ημέρα</div>
                                            </div>
                                            <div class="feature">
                                                <div class="check"><img src={img_check} alt="" /></div>
                                                <div>Ώρα ανανέωσης 7:00 - 9:00</div>
                                            </div>
                                            <div class="feature">
                                                <div class="check"><img src={img_check} alt="" /></div>
                                                <div>Τουλάχιστον +10% πωλήσεις τον μήνα</div>
                                            </div>
                                            <div class="feature">
                                                <div class="check"><img src={img_check} alt="" /></div>
                                                <div>Ανταγωνιστικό πλεονέκτημα λόγο πρωινών ανανεώσεων</div>
                                            </div>
                                        </div>
                                        <form class="subscription-form">
                                            <input type="radio" id="morning-month-sub" name="choice-morning" value="price_1NP5pdLhHA6SPddpV3HHsaiz" disabled={(() => {if (activeSubscriptionNameId === "morning-month-sub") return true})()} defaultChecked={(() => {
                                                switch (activeSubscriptionNameId) {
                                                    case "evening-month-sub": return true;
                                                    case "evening-year-sub": return true;
                                                    case "morning-month-sub": return false;
                                                    case "morning-year-sub": return false;
                                                }
                                            })()} />
                                            <label id="morning-month-sub-label" class={(() => {if (activeSubscriptionNameId === "morning-month-sub") {return "br subscribed"} else {return "br"}})()} for="morning-month-sub">
                                                <div class="title_check">
                                                    <div class="input-check">
                                                        <img class="check" src={img_check} alt="" />
                                                    </div>
                                                    <div class="input-title">Μήνας</div>
                                                </div>
                                                <div class="input-description">Εγγραφή γιά ένα μήνα. <br/>Ακυρώστε οποιαδήποτε στιγμή.</div>
                                                <div class="price_period">
                                                    <div class="input-price">25</div>
                                                    <div class="input-period">/μήνα</div>
                                                </div>
                                            </label>
                                            <input type="radio" id="morning-year-sub" name="choice-morning" value="price_1NP5pdLhHA6SPddp7l2diw1l" disabled={(() => {if (activeSubscriptionNameId === "morning-year-sub") return true})()} defaultChecked={(() => {
                                                switch (activeSubscriptionNameId) {
                                                    case "evening-month-sub": return false;
                                                    case "evening-year-sub": return false;
                                                    case "morning-month-sub": return false;
                                                    case "morning-year-sub": return false;
                                                }
                                            })()} />
                                            <label id="morning-year-sub-label" class={(() => {if (activeSubscriptionNameId === "morning-year-sub") {return "subscribed"}})()} for="morning-year-sub">
                                                <div class="title_check">
                                                    <div class="input-check">
                                                        <img class="check" src={img_check} alt="" />
                                                    </div>
                                                    <div class="input-title">Έτος</div>
                                                </div>
                                                <div class="input-description">Εγγραφή γιά ένα έτος.</div>
                                                <div class="price_period">
                                                    <div class="input-price">250</div>
                                                    <div class="input-period">/έτος</div>
                                                </div>
                                                <div class="input-label">Έκπτωση -20%</div>
                                            </label>
                                        </form>
                                        <div class="action-btn" onClick={() => subscriptionAction({value: "morning", action: "update", props})}>Αλλαγή<img src={img_arrow} alt="" /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function Subscribed(props) {
    const {user, userUID, userFirestoreData, stripe, stripeCustomerId, subscriptions, subscriptions_object, subscriptionInfo, subscriptionNameId_index, fn} = props.data;
    const {isSubscribed, isSubscriptionPaused, isSubscriptionPaid, toBeCancelled, subscriptionId, dateSubEnds, active_subscription_object} = subscriptionInfo;
    const activeSubscriptionNameId = active_subscription_object?.nameId;
    const unitAmount = active_subscription_object?.unitAmount;
    const recurringInterval = active_subscription_object?.recurringInterval;
    const recurringInterval_gr_short = (() => {switch (recurringInterval) { case "month": return "ΜΗΝΑΣ"; case "year": return "ΕΤΟΣ"; }})();
    const recurringInterval_gr_long = (() => {switch (recurringInterval) { case "month": return "Μηνιαία συνδρομή"; case "year": return "Ετήσια συνδρομή"; }})();
    const {getSubscriptions, setLoading} = fn;
    const {img_check, img_morning, img_evening, img_arrow, img_arrow_blue} = props.data.img;
    
    return (
        <>
            <div class="settings-container-outer toBeCanceled">
                <div class="settings-container-inner" style={{"width": "100%"}}>
                    <div class="data-panel-settings settings-cargr">
                        <div class="settings-head">
                            <div class="settings-heading">Ρυθμίσεις συνδρομής</div>
                            <div class="settings-subheading">Έχετε εγγραφεί στο {
                                (() => {
                                    switch (activeSubscriptionNameId) {
                                        case subscriptionNameId_index.evening.month: 
                                        case subscriptionNameId_index.evening.year: return "πακέτο συνδρομής"; break;
                                        case subscriptionNameId_index.morning.month:
                                        case subscriptionNameId_index.morning.year: return "πρωινό πακέτο ανανεώσεων"; break;
                                    }
                                })()
                            }</div>
                        </div>
                        {/* <div class="settings-group">
                            <div class="settings-group-head">
                                <div class="settings-group-heading">Τρέχουσα συνδρομή</div>
                            </div>
                            <div class="settings-group-body">
                                <div class="settings-item center-flex">
                                    <div class="settings-item-heading" id="active-sub">
                                        <div class="settings-item-option">
                                            <div class="settings-item-option-wrapper">
                                                <div class="settings-item-option-action">
                                                    <img class="sub-icon" src={
                                                        (() => {
                                                            switch (activeSubscriptionNameId) {
                                                                case subscriptionNameId_index.evening.month: 
                                                                case subscriptionNameId_index.evening.year: return img_evening; break;
                                                                case subscriptionNameId_index.morning.month:
                                                                case subscriptionNameId_index.morning.year: return img_morning; break;
                                                            }
                                                        })()
                                                    } alt="" />
                                                </div>
                                                <div class="settings-item-option-inner-wrapper">
                                                    <div class="settings-item-option-head">
                                                        <div class="settings-item-option-heading">{
                                                            (() => {
                                                                switch (activeSubscriptionNameId) {
                                                                    case subscriptionNameId_index.evening.month: 
                                                                    case subscriptionNameId_index.evening.year: return "Πακέτο Συνδρομής"; break;
                                                                    case subscriptionNameId_index.morning.month:
                                                                    case subscriptionNameId_index.morning.year: return "Πρωινό Πακέτο"; break;
                                                                }
                                                            })()
                                                        }</div>
                                                        <div class="interval">{recurringInterval_gr_short}</div>
                                                    </div>
                                                    <div class="settings-item-option-subheading">{
                                                        (() => {
                                                            switch (activeSubscriptionNameId) {
                                                                case subscriptionNameId_index.evening.month: 
                                                                case subscriptionNameId_index.evening.year: return "Ώρα ανανέωσης: Custom"; break;
                                                                case subscriptionNameId_index.morning.month:
                                                                case subscriptionNameId_index.morning.year: return "Ώρα ανανέωσης 7:00 - 9:00"; break;
                                                            }
                                                        })()
                                                    }</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {
                            (() => {
                                if (toBeCancelled) {
                                    if (!isSubscriptionPaused) { return (<><SubscriptionOptions_toBeCanceled data={props.data} /></>) }
                                    if (isSubscriptionPaused) { return (<><SubscriptionOptions_toBeCanceled_And_pausedErrCred data={props.data} /></>) }
                                } else {
                                    if (!isSubscriptionPaid) { return (<><SubscriptionOptions_pastDue data={props.data} /></>) }
                                    else if (isSubscriptionPaused) { return (<><SubscriptionOptions_pausedErrCred data={props.data} /></>) }
                                    else { return (<><SubscriptionOptions_ok data={props.data} /></>) }
                                }
                            })()
                        }
                        <Portal data={props.data} />
                        <TaxData data={props.data} />
                    </div>
                </div>
            </div>
        </>
    )
}

function Portal(props) {
    const {isSubscribed, isSubscriptionPaid} = props.data.subscriptionInfo;
    const {setLoading} = props.data.fn;
    const {img_arrow_blue, img_arrow} = props.data.img;

    return (
        <div class="settings-group">
            <div class="settings-group-head">
                <div class="settings-group-heading">Portal πελάτη</div>
                <div class="settings-group-sub-heading">Επεξεργαστείτε και δείτε τις πληροφορίες σας στο portal πελάτη</div>
            </div>
            <div class="settings-group-body">
                <div class="settings-item">
                    <div class="settings-item-heading">
                        {
                            (() => {
                                if (isSubscriptionPaid) {
                                    return (<><div class="settings-button portal" onClick={() => launchPortal({setLoading, isSubscribed})}>Portal <img src={img_arrow_blue} alt="" /></div></>);
                                } else {
                                    return (<><div class="settings-button portal blue" onClick={() => launchPortal({setLoading, isSubscribed})}>Portal <img src={img_arrow} alt="" /></div></>);
                                }
                            })()
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

function TaxData(props) {
    const {userFirestoreData, userUID} = props.data;
    const {isSubscribed, isSubscriptionPaid} = props.data.subscriptionInfo;
    const {setLoading} = props.data.fn;
    const {img_arrow_blue, img_arrow} = props.data.img;

    const taxData = userFirestoreData?.taxData || "";
    const email = taxData?.email || "";
    const eponymia = taxData?.eponymia || "";
    const epaggelma = taxData?.epaggelma || "";
    const tilefono = taxData?.tilefono || "";
    const dieythynsi = taxData?.dieythynsi || "";
    const afm = taxData?.afm || "";
    const doy = taxData?.doy || "";

    return (
        <div class="settings-group">
            <div class="settings-group-head">
                <div class="settings-group-heading">Στοιχεία τιμολογίου</div>
                <div class="settings-group-sub-heading">Επεξεργαστείτε τα στοιχεία τιμολογίου</div>
            </div>
            <div class="settings-group-body">
                <div class="settings-item">
                    <div class="settings-item-heading">Email για τιμολόγια</div>
                    <div class="settings-item-options">
                        <div class="settings-item-option wrap-768 tax-input" style={{"alignItems": "center"}}>
                            <div class="settings-item-option-action">
                                <input type="text" id="email" placeholder="Εισάγετε email" />
                            </div>
                            {(() => {
                                if (email) {
                                    return (
                                        <>
                                            <div class="settings-item-option-head account-status-connected" >
                                                <div class="settings-item-option-subheading">{email}</div>
                                            </div>
                                        </>
                                    );
                                } else { return (<></>); }
                            })()}
                        </div>
                    </div>
                </div>
                <div class="settings-item">
                    <div class="settings-item-heading">Επωνυμία</div>
                    <div class="settings-item-options">
                        <div class="settings-item-option wrap-768 tax-input" style={{"alignItems": "center"}}>
                            <div class="settings-item-option-action">
                                <input type="text" id="eponymia" placeholder="Εισάγετε επωνυμία" />
                            </div>
                            {(() => {
                                if (eponymia) {
                                    return (
                                        <>
                                            <div class="settings-item-option-head account-status-connected" >
                                                <div class="settings-item-option-subheading">{eponymia}</div>
                                            </div>
                                        </>
                                    );
                                } else { return (<></>); }
                            })()}
                        </div>
                    </div>
                </div>
                <div class="settings-item">
                    <div class="settings-item-heading">Επάγγελμα</div>
                    <div class="settings-item-options">
                        <div class="settings-item-option wrap-768 tax-input" style={{"alignItems": "center"}}>
                            <div class="settings-item-option-action">
                                <input type="text" id="epaggelma" placeholder="Εισάγετε επάγγελμα" />
                            </div>
                            {(() => {
                                if (epaggelma) {
                                    return (
                                        <>
                                            <div class="settings-item-option-head account-status-connected" >
                                                <div class="settings-item-option-subheading">{epaggelma}</div>
                                            </div>
                                        </>
                                    );
                                } else { return (<></>); }
                            })()}
                        </div>
                    </div>
                </div>
                <div class="settings-item">
                    <div class="settings-item-heading">Τηλέφωνο</div>
                    <div class="settings-item-options">
                        <div class="settings-item-option wrap-768 tax-input" style={{"alignItems": "center"}}>
                            <div class="settings-item-option-action">
                                <input type="text" id="tilefono" placeholder="Εισάγετε τηλέφωνο" />
                            </div>
                            {(() => {
                                if (tilefono) {
                                    return (
                                        <>
                                            <div class="settings-item-option-head account-status-connected" >
                                                <div class="settings-item-option-subheading">{tilefono}</div>
                                            </div>
                                        </>
                                    );
                                } else { return (<></>); }
                            })()}
                        </div>
                    </div>
                </div>
                <div class="settings-item">
                    <div class="settings-item-heading">Διεύθυνση</div>
                    <div class="settings-item-options">
                        <div class="settings-item-option wrap-768 tax-input" style={{"alignItems": "center"}}>
                            <div class="settings-item-option-action">
                                <input type="text" id="dieythynsi" placeholder="Εισάγετε διεύθυνση" />
                            </div>
                            {(() => {
                                if (dieythynsi) {
                                    return (
                                        <>
                                            <div class="settings-item-option-head account-status-connected" >
                                                <div class="settings-item-option-subheading">{dieythynsi}</div>
                                            </div>
                                        </>
                                    );
                                } else { return (<></>); }
                            })()}
                        </div>
                    </div>
                </div>
                <div class="settings-item">
                    <div class="settings-item-heading">ΑΦΜ</div>
                    <div class="settings-item-options">
                        <div class="settings-item-option wrap-768 tax-input" style={{"alignItems": "center"}}>
                            <div class="settings-item-option-action">
                                <input type="text" id="afm" placeholder="Εισάγετε ΑΦΜ" />
                            </div>
                            {(() => {
                                if (afm) {
                                    return (
                                        <>
                                            <div class="settings-item-option-head account-status-connected" >
                                                <div class="settings-item-option-subheading">{afm}</div>
                                            </div>
                                        </>
                                    );
                                } else { return (<></>); }
                            })()}
                        </div>
                    </div>
                </div>
                <div class="settings-item">
                    <div class="settings-item-heading">ΔΟΥ</div>
                    <div class="settings-item-options">
                        <div class="settings-item-option wrap-768 tax-input" style={{"alignItems": "center"}}>
                            <div class="settings-item-option-action">
                                <input type="text" id="doy" placeholder="Εισάγετε ΔΟΥ" />
                            </div>
                            {(() => {
                                if (doy) {
                                    return (
                                        <>
                                            <div class="settings-item-option-head account-status-connected" >
                                                <div class="settings-item-option-subheading">{doy}</div>
                                            </div>
                                        </>
                                    );
                                } else { return (<></>); }
                            })()}
                        </div>
                    </div>
                </div>
                <div class="settings-item">
                    <div class="settings-item-heading">
                        <div class="settings-button portal blue" onClick={() => saveTaxData(userUID)}>Αποθήκευση</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function SubscriptionOptions_ok(props) {
    const {user, userUID, userFirestoreData, stripe, stripeCustomerId, subscriptions, subscriptions_object, subscriptionInfo, subscriptionNameId_index, fn} = props.data;
    const {isSubscribed, isSubscriptionPaused, isSubscriptionPaid, toBeCancelled, subscriptionId, dateSubEnds, active_subscription_object} = subscriptionInfo;
    const activeSubscriptionNameId = active_subscription_object?.nameId;
    const unitAmount = active_subscription_object?.unitAmount;
    const recurringInterval = active_subscription_object?.recurringInterval;
    const recurringInterval_gr_short = (() => {switch (recurringInterval) { case "month": return "ΜΗΝΑΣ"; case "year": return "ΕΤΟΣ"; }})();
    const recurringInterval_gr_long = (() => {switch (recurringInterval) { case "month": return "Μηνιαία συνδρομή"; case "year": return "Ετήσια συνδρομή"; }})();
    const {getSubscriptions, setLoading} = fn;
    const navigate = useNavigate();
    return (
        <>
            <div class="settings-group">
                <div class="settings-group-head">
                    <div class="settings-group-heading">Γενικές επιλογές</div>
                    <div class="settings-group-sub-heading">Επεξεργαστείτε και δείτε πληροφορίες σχετικά με την συνδρομή σας</div>
                </div>
                <div class="settings-group-body">
                    <div class="settings-item">
                        <div class="settings-item-heading">Κατάσταση συνδρομής</div>
                        <div class="settings-item-options">
                            <div class="settings-item-option">
                                <div class="settings-item-option-action">
                                    <div style={{ "margin-top": "3px", "width": "10px", "height": "10px", "border-radius": "5px", "background-color": "limegreen" }}></div>
                                </div>
                                <div class="settings-item-option-head">
                                    <div class="settings-item-option-heading">Ενεργή</div>
                                    <div class="settings-item-option-subheading">{recurringInterval_gr_long}. Επόμενη πληρωμή <span class="nowrap">{(unitAmount / 100).toFixed(2).toString().replace(".", ",")} €</span> στίς <span class="nowrap">{dateSubEnds}</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div class="settings-item">
                        <div class="settings-item-heading">Επεξεργασία συνδρομής</div>
                        <div class="settings-item-options">
                            <div class="settings-item-option wrap-768">
                                <div class="settings-item-option-action">
                                    <div class="settings-button" onClick={() => navigate(`/update`)} >Αλλαγή συνδρομής</div>
                                </div>
                                <div class="settings-item-option-head account-status-connected">
                                    <div class="settings-item-option-subheading">Αλλάξτε ή αναβαθμίστε την συνδρομή σας. Επιλέξτε ανάμεσα στο πρωινό και απογευματινό πακέτο, ή αλλάξτε σε μηνιαία ή ετήσια συνδρομή.</div>
                                </div>
                            </div>
                            <div class="settings-item-option account-status-connected">
                                <div class="settings-item-option-head">
                                    <div class="settings-item-option-heading action" onClick={() => cancelSubscription({setLoading, isSubscribed, subscriptionId, toBeCancelled, getSubscriptions})}>Ακύρωση συνδρομής</div>
                                    <div class="settings-item-option-subheading">Ακυρώνοντας την συνδρομή σας θα σταματήσετε να χρεώνεστε. Οι αυτόματες ανανεώσεις θα συνεχίσουν να λειτουργούν εώς την ημερομηνία λήξης του ενεργού πακέτου.</div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}

function SubscriptionOptions_toBeCanceled(props) {
    const {user, userUID, userFirestoreData, stripe, stripeCustomerId, subscriptions, subscriptions_object, subscriptionInfo, subscriptionNameId_index, fn} = props.data;
    const {isSubscribed, isSubscriptionPaused, isSubscriptionPaid, toBeCancelled, subscriptionId, dateSubEnds, active_subscription_object} = subscriptionInfo;
    const activeSubscriptionNameId = active_subscription_object?.nameId;
    const recurringInterval = active_subscription_object?.recurringInterval;
    const recurringInterval_gr_short = (() => {switch (recurringInterval) { case "month": return "ΜΗΝΑΣ"; case "year": return "ΕΤΟΣ"; }})();
    const recurringInterval_gr_long = (() => {switch (recurringInterval) { case "month": return "Μηνιαία συνδρομή"; case "year": return "Ετήσια συνδρομή"; }})();
    const {getSubscriptions, setLoading} = fn;
    return (
        <>
            <div class="settings-group">
                <div class="settings-group-head">
                    <div class="settings-group-heading">Γενικές επιλογές</div>
                    <div class="settings-group-sub-heading">Επεξεργαστείτε και δείτε πληροφορίες σχετικά με την συνδρομή σας</div>
                </div>
                <div class="settings-group-body">
                    <div class="settings-item">
                        <div class="settings-item-heading">Κατάσταση συνδρομής</div>
                        <div class="settings-item-options">
                            <div class="settings-item-option">
                                <div class="settings-item-option-action">
                                    <div style={{ "margin-top": "3px", "width": "10px", "height": "10px", "border-radius": "5px", "background-color": "orange" }}></div>
                                </div>
                                <div class="settings-item-option-head">
                                    <div class="settings-item-option-heading">Ακυρώθηκε</div>
                                    <div class="settings-item-option-subheading">Η συνδρομή θα τερματιστεί στίς {dateSubEnds}. Δεν θα ξαναχρεωθείτε.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="settings-item">
                        <div class="settings-item-heading">Επεξεργασία συνδρομής</div>
                        <div class="settings-item-options">
                            <div class="settings-item-option wrap-768">
                                <div class="settings-item-option-action">
                                    <div class="settings-button undo-cancel" onClick={() => undoCancelSubscription({setLoading, isSubscribed, subscriptionId, toBeCancelled, getSubscriptions})}>Επαναφορά συνδρομής</div>
                                </div>
                                {/* <div class="settings-item-option-head account-status-connected" style={{"width": "100%"}}>
                                    <div class="settings-item-option-subheading"></div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function SubscriptionOptions_pastDue(props) {
    const {user, userUID, userFirestoreData, stripe, stripeCustomerId, subscriptions, subscriptions_object, subscriptionInfo, subscriptionNameId_index, fn} = props.data;
    const {isSubscribed, isSubscriptionPaused, isSubscriptionPaid, toBeCancelled, subscriptionId, dateSubEnds, active_subscription_object, dateSubEndsPlus7Days, daySubEndsPlus7Days, daySubEnds} = subscriptionInfo;
    const activeSubscriptionNameId = active_subscription_object?.nameId;
    const recurringInterval = active_subscription_object?.recurringInterval;
    const recurringInterval_gr_short = (() => {switch (recurringInterval) { case "month": return "ΜΗΝΑΣ"; case "year": return "ΕΤΟΣ"; }})();
    const recurringInterval_gr_long = (() => {switch (recurringInterval) { case "month": return "Μηνιαία συνδρομή"; case "year": return "Ετήσια συνδρομή"; }})();
    const {getSubscriptions, setLoading} = fn;
    return (
        <>
            <div class="settings-group">
                <div class="settings-group-head">
                    <div class="settings-group-heading">Γενικές επιλογές</div>
                    <div class="settings-group-sub-heading">Επεξεργαστείτε και δείτε πληροφορίες σχετικά με την συνδρομή σας</div>
                </div>
                <div class="settings-group-body">
                    <div class="settings-item">
                        <div class="settings-item-heading">Κατάσταση συνδρομής</div>
                        <div class="settings-item-options">
                            <div class="settings-item-option">
                                <div class="settings-item-option-action">
                                    <div style={{ "margin-top": "3px", "width": "10px", "height": "10px", "border-radius": "5px", "background-color": "orange" }}></div>
                                </div>
                                <div class="settings-item-option-head">
                                    <div class="settings-item-option-heading">Έχει διακοπεί</div>
                                    <div class="settings-item-option-subheading">Η συνδρομή έχει λήξει {(() => {if (daySubEnds > 1) {return "στις"} else {return "στην"}})()} {dateSubEnds} και θα ακυρωθεί {(() => {if (daySubEndsPlus7Days > 1) {return "στις"} else {return "στην"}})()} {dateSubEndsPlus7Days} λόγω μη πληρωμής. Για να συνεχίσετε να έχετε πρόσβαση στις ανανεώσεις ενημερώστε τα στοιχεία πληρωμής σας κάνοντας κλικ στο κουμπί "portal πελάτη" παρακάτω.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div class="settings-item">
                        <div class="settings-item-heading">Επεξεργασία συνδρομής</div>
                        <div class="settings-item-options">
                            <div class="settings-item-option wrap-768">
                                <div class="settings-item-option-action">
                                    <div class="settings-button">Αλλαγή συνδρομής</div>
                                </div>
                                <div class="settings-item-option-head account-status-connected">
                                    <div class="settings-item-option-subheading">Αλλάξτε ή αναβαθμίστε την συνδρομή σας. Επιλέξτε ανάμεσα στο πρωινό και απογευματινό πακέτο, ή αλλάξτε σε μηνιαία ή ετήσια συνδρομή.</div>
                                </div>
                            </div>
                            <div class="settings-item-option account-status-connected">
                                <div class="settings-item-option-head">
                                    <div class="settings-item-option-heading action" onClick={() => deleteSubscription({setLoading, isSubscribed, subscriptionId, getSubscriptions})}>Ακύρωση συνδρομής</div>
                                    <div class="settings-item-option-subheading"></div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}

function SubscriptionOptions_pausedErrCred(props) {
    const {user, userUID, userFirestoreData, stripe, stripeCustomerId, subscriptions, subscriptions_object, subscriptionInfo, subscriptionNameId_index, fn} = props.data;
    const {isSubscribed, isSubscriptionPaused, isSubscriptionPaid, toBeCancelled, subscriptionId, dateSubEnds, active_subscription_object} = subscriptionInfo;
    const activeSubscriptionNameId = active_subscription_object?.nameId;
    const unitAmount = active_subscription_object?.unitAmount;
    const recurringInterval = active_subscription_object?.recurringInterval;
    const recurringInterval_gr_short = (() => {switch (recurringInterval) { case "month": return "ΜΗΝΑΣ"; case "year": return "ΕΤΟΣ"; }})();
    const recurringInterval_gr_long = (() => {switch (recurringInterval) { case "month": return "Μηνιαία συνδρομή"; case "year": return "Ετήσια συνδρομή"; }})();
    const {getSubscriptions, setLoading} = fn;
    const navigate = useNavigate();
    return (
        <>
            <div class="settings-group">
                <div class="settings-group-head">
                    <div class="settings-group-heading">Γενικές επιλογές</div>
                    <div class="settings-group-sub-heading">Επεξεργαστείτε και δείτε πληροφορίες σχετικά με την συνδρομή σας</div>
                </div>
                <div class="settings-group-body">
                    <div class="settings-item">
                        <div class="settings-item-heading">Κατάσταση συνδρομής</div>
                        <div class="settings-item-options">
                            <div class="settings-item-option">
                                <div class="settings-item-option-action">
                                    <div style={{ "margin-top": "3px", "width": "10px", "height": "10px", "border-radius": "5px", "background-color": "orange" }}></div>
                                </div>
                                <div class="settings-item-option-head">
                                    <div class="settings-item-option-heading">Σε παύση</div>
                                    <div class="settings-item-option-subheading">Η συνδρομή σας έχει ανασταλεί λόγω αποσύνδεσης του λογαριασμού Car. Παρακαλούμε επανασυνδέστε το Car και η συνδρομή σας θα συνεχιστεί αυτόματα. Κατά τη διάρκεια της αναστολής δεν χρεώνεστε και ο υπολειπόμενος χρόνος της συνδρομής σας δεν μειώνεται.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div class="settings-item">
                        <div class="settings-item-heading">Επεξεργασία συνδρομής</div>
                        <div class="settings-item-options">
                            <div class="settings-item-option wrap-768">
                                <div class="settings-item-option-action">
                                    <div class="settings-button" onClick={() => navigate(`/update`)} >Αλλαγή συνδρομής</div>
                                </div>
                                <div class="settings-item-option-head account-status-connected">
                                    <div class="settings-item-option-subheading">Αλλάξτε ή αναβαθμίστε την συνδρομή σας. Επιλέξτε ανάμεσα στο πρωινό και απογευματινό πακέτο, ή αλλάξτε σε μηνιαία ή ετήσια συνδρομή.</div>
                                </div>
                            </div>
                            <div class="settings-item-option account-status-connected">
                                <div class="settings-item-option-head">
                                    <div class="settings-item-option-heading action" onClick={() => cancelSubscription({setLoading, isSubscribed, subscriptionId, toBeCancelled, getSubscriptions})}>Ακύρωση συνδρομής</div>
                                    <div class="settings-item-option-subheading">Ακυρώνοντας την συνδρομή σας θα σταματήσετε να χρεώνεστε. Οι αυτόματες ανανεώσεις θα συνεχίσουν να λειτουργούν εώς την ημερομηνία λήξης του ενεργού πακέτου εφόσον συνδέσετε το Car.</div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}

function SubscriptionOptions_toBeCanceled_And_pausedErrCred(props) {
    const {user, userUID, userFirestoreData, stripe, stripeCustomerId, subscriptions, subscriptions_object, subscriptionInfo, subscriptionNameId_index, fn} = props.data;
    const {isSubscribed, isSubscriptionPaused, isSubscriptionPaid, toBeCancelled, subscriptionId, dateSubEnds, active_subscription_object} = subscriptionInfo;
    const activeSubscriptionNameId = active_subscription_object?.nameId;
    const recurringInterval = active_subscription_object?.recurringInterval;
    const recurringInterval_gr_short = (() => {switch (recurringInterval) { case "month": return "ΜΗΝΑΣ"; case "year": return "ΕΤΟΣ"; }})();
    const recurringInterval_gr_long = (() => {switch (recurringInterval) { case "month": return "Μηνιαία συνδρομή"; case "year": return "Ετήσια συνδρομή"; }})();
    const {getSubscriptions, setLoading} = fn;
    return (
        <>
            <div class="settings-group">
                <div class="settings-group-head">
                    <div class="settings-group-heading">Γενικές επιλογές</div>
                    <div class="settings-group-sub-heading">Επεξεργαστείτε και δείτε πληροφορίες σχετικά με την συνδρομή σας</div>
                </div>
                <div class="settings-group-body">
                    <div class="settings-item">
                        <div class="settings-item-heading">Κατάσταση συνδρομής</div>
                        <div class="settings-item-options">
                            <div class="settings-item-option">
                                <div class="settings-item-option-action">
                                    <div style={{ "margin-top": "3px", "width": "10px", "height": "10px", "border-radius": "5px", "background-color": "orange" }}></div>
                                </div>
                                <div class="settings-item-option-head">
                                    <div class="settings-item-option-heading">Σε παύση & Ακυρώθηκε</div>
                                    <div class="settings-item-option-subheading">Οι ανανεώσεις έχουν ανασταλεί λόγω αποσύνδεσης του λογαριασμού Car. Ωστόσο η συνδρομή σας έχει ακυρωθεί και δεν θα ξαναχρεωθείτε. Εφόσον επανασυνδέστε το Car οι ανανεώσεις θα συνεχιστούν κανονικά μέχρι την ημερομηνία λήξη της συνδρομής στίς {dateSubEnds}.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="settings-item">
                        <div class="settings-item-heading">Επεξεργασία συνδρομής</div>
                        <div class="settings-item-options">
                            <div class="settings-item-option wrap-768">
                                <div class="settings-item-option-action">
                                    <div class="settings-button undo-cancel" onClick={() => undoCancelSubscription({setLoading, isSubscribed, subscriptionId, toBeCancelled, getSubscriptions})}>Επαναφορά συνδρομής</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

///////////////

async function cancelSubscription({setLoading, isSubscribed, subscriptionId, toBeCancelled, getSubscriptions}) {
    if (isSubscribed && !toBeCancelled) {
        setLoading(true);
        await fetchFromAPI('subscriptions/cancel/' + subscriptionId, { method: 'PATCH' });
        await getSubscriptions();
        setLoading(false);
    }
};

async function undoCancelSubscription({setLoading, isSubscribed, subscriptionId, toBeCancelled, getSubscriptions}) {
    if (isSubscribed && toBeCancelled) {
        setLoading(true);
        await fetchFromAPI('subscriptions/undo-cancel/' + subscriptionId, { method: 'PATCH' });
        await getSubscriptions();
        setLoading(false);
    }
};

async function deleteSubscription({setLoading, isSubscribed, subscriptionId, getSubscriptions}) {
    if (isSubscribed) {
        setLoading(true);
        await fetchFromAPI('subscriptions/delete/' + subscriptionId, { method: 'PATCH' });
        await getSubscriptions();
        setLoading(false);
    }
};

async function launchPortal({setLoading, isSubscribed}) {
    if (isSubscribed) {
        setLoading(true);
        const { url } = await fetchFromAPI('portal');
        window.location.href = url;
    }
};

async function saveTaxData(uid) {
    const email = document.getElementById("email").value || false;
    const eponymia = document.getElementById("eponymia").value || false;
    const epaggelma = document.getElementById("epaggelma").value || false;
    const tilefono = document.getElementById("tilefono").value || false;
    const dieythynsi = document.getElementById("dieythynsi").value || false;
    const afm = document.getElementById("afm").value || false;
    const doy = document.getElementById("doy").value || false;

    const taxData = {};
    if (email) taxData.email = email;
    if (eponymia) taxData.eponymia = eponymia;
    if (epaggelma) taxData.epaggelma = epaggelma;
    if (tilefono) taxData.tilefono = tilefono;
    if (dieythynsi) taxData.dieythynsi = dieythynsi;
    if (afm) taxData.afm = afm;
    if (doy) taxData.doy = doy;

    document.getElementById("email").value = "";
    document.getElementById("eponymia").value = "";
    document.getElementById("epaggelma").value = "";
    document.getElementById("tilefono").value = "";
    document.getElementById("dieythynsi").value = "";
    document.getElementById("afm").value = "";
    document.getElementById("doy").value = "";

    await db.collection('users').doc(uid).set({ taxData }, { merge: true });
};

async function subscriptionAction({value, action, props}) {
    const {stripe, stripeCustomerId} = props.data;
    const {isSubscribed} = props.data.subscriptionInfo;
    const {setLoading} = props.data.fn;
    const choice = document.getElementsByName("choice-" + value);
    let priceValue = "";
    for (let i = 0; i < choice.length; i++) {
        if (choice[i].checked) {priceValue = choice[i].value; break;}
    }
    if (!priceValue) {console.error("No price value selected"); return "";}

    if (action === "subscribe") {
        setLoading(true);
        redirectToCheckout(priceValue, isSubscribed, stripe, stripeCustomerId);
    } 
    else if (action === "update") {
        setLoading(true);
        redirectToCheckout_forUpdate(priceValue, isSubscribed, stripe, stripeCustomerId);
    }
};

async function redirectToCheckout(priceValue, isSubscribed, stripe, stripeCustomerId) {
    if (isSubscribed) {console.error("User already subscribed"); return "";}
    const body = {
        customer: stripeCustomerId,
        mode: 'subscription',
        line_items: [
            {
                price: priceValue,
                quantity: 1,
            }
        ]
    };

    const { id: sessionId } = await fetchFromAPI('checkouts', { body });
    const { error } = await stripe.redirectToCheckout({ sessionId });
    if (error) { console.log(error) }
};

async function redirectToCheckout_forUpdate(priceValue, isSubscribed, stripe, stripeCustomerId) {
    if (!isSubscribed) {console.error("User is not subscribed"); return "";}
    const body = {
        customer: stripeCustomerId,
        mode: 'subscription',
        line_items: [
            {
                price: priceValue,
                quantity: 1,
            }
        ]
    };

    const { id: sessionId } = await fetchFromAPI('checkouts-update', { body });
    const { error } = await stripe.redirectToCheckout({ sessionId });
    if (error) { console.log(error) }
};