import { auth } from "./firebase";

// Define Stripe API url based on the working enviroment (dev / prod)
const {DEVELOPMENT_API, PRODUCTION_API} = require("./env.json");
let API = PRODUCTION_API;
if (process.env.NODE_ENV !== 'production') {
    API = DEVELOPMENT_API;
}

/**
 * A helper function to fetch data from your API
 */
export async function fetchFromAPI(endpointURL, opts) {
    const {method, body} = { method: 'POST', body: null, ...opts};

    const user = auth.currentUser;
    const token = user && (await user.getIdToken());

    const res = await fetch(`${API}/${endpointURL}`, {
        method,
        ...(body && {body: JSON.stringify(body)}),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });

    return res.json();
}


export class CheckoutMap {
    constructor(mode = "payment") {
        this.line_items = [];
        this.mode = mode;
    }

    addLineItem(product) {
        this.line_items.push({
            price_data: {
                currency: product.currency,
                product_data: {
                    name: product.name,
                    description: product.description,
                    images: [...product.images],
                },
                unit_amount: product.amount
            },
            quantity: product.quantity
        });
    }
};

export class CheckoutMap_Subscription {
    constructor(mode = "subscription") {
        this.line_items = [];
        this.mode = mode;
    }

    addLineItem(product) {
        this.line_items.push({
            price: product.price,
            quantity: 1
        });
    }
};