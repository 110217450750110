import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';

export default function Logout_debug(props) {
    const navigate = useNavigate();
        return (
            <>
            {
                (() => {
                    navigate(`/`);
                    auth.signOut();
                })()
            }
            </>
        )
}